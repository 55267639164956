<template>
    <div>
        <v-card outlined class="mt-0">
            <v-card-title class="d-flex justify-space-between align-center">
                <div>LISTADO DE PACIENTES</div>
                <v-spacer />
                <v-btn color="primary" @click="abrirDialogoAgregar">Registrar Paciente</v-btn>
            </v-card-title>

            <v-card-text>
                <v-text-field v-model="search" label="Buscar por nombre o documento" append-icon="mdi-magnify"
                    class="mb-3" clearable hide-details dense />

                <v-data-table-server :headers="headers" :items="pacientes" :loading="isLoading"
                    :items-per-page="itemsPerPage" :items-length="total" v-model:options="options" density="compact"
                    @update:options="cargarPacientes" class="elevation-0">
                    <template v-slot:[`item.nombres`]="{ item }">
                        {{ item.nombres }} {{ item.apellido_paterno }} {{ item.apellido_materno }}
                    </template>

                    <template v-slot:[`item.numero_documento`]="{ item }">
                        {{ item.numero_documento }}
                    </template>

                    <template v-slot:[`item.celular`]="{ item }">
                        {{ item.celular || 'Sin número' }}
                    </template>

                    <template v-slot:[`item.correo`]="{ item }">
                        {{ item.correo || 'No registrado' }}
                    </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-btn icon @click="verDetalle(item)" variant="text">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon color="red" @click="confirmarEliminar(item)" variant="text">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table-server>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogAgregar" max-width="800px">
            <v-card>
                <v-card-title>Registrar Nuevo Paciente</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <!-- 🔍 Campo con búsqueda por DNI -->
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.numero_documento" label="Número de Documento"
                                    @click:append="buscarPorDocumento" append-icon="mdi-magnify" clearable required />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.nombres" label="Nombres"
                                    :readonly="pacienteEncontrado" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.apellido_paterno" label="Apellido Paterno"
                                    :readonly="pacienteEncontrado" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.apellido_materno" label="Apellido Materno"
                                    :readonly="pacienteEncontrado" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.fecha_nacimiento" label="Fecha Nacimiento"
                                    type="date" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select v-model="nuevoPaciente.sexo" :items="['M', 'F']" label="Sexo" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.celular" label="Celular" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.correo" label="Correo" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.direccion" label="Dirección" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.puesto" label="Puesto" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.nro_registro" label="N° Registro" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="nuevoPaciente.ubigeo_nacimiento" label="Ubigeo Nacimiento" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue" @click="guardarPaciente">Guardar</v-btn>
                    <v-btn text @click="dialogAgregar = false">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Diálogo de confirmación -->
        <ConfirmDialog :isVisible="showDeleteDialog" title="Eliminar Paciente"
            message="¿Está seguro que desea eliminar este paciente?" @update:isVisible="showDeleteDialog = $event"
            @confirm="eliminarPaciente" @cancel="cancelarEliminar" />
    </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "ListadoPacientesView",
    components: { ConfirmDialog },
    data() {
        return {
            dialogAgregar: false,
            showDeleteDialog: false,
            pacienteAEliminar: null,
            itemsPerPage: 10,
            options: {},
            search: "",
            pacienteEncontrado: false,
            nuevoPaciente: {
                nombres: "",
                apellido_paterno: "",
                apellido_materno: "",
                numero_documento: "",
                fecha_nacimiento: "",
                sexo: "",
                celular: "",
                correo: "",
                direccion: "",
                puesto: "",
                nro_registro: "",
                ubigeo_nacimiento: "",
                estado: 1,
            },
            estadoOptions: [
                { text: "Activo", value: 1 },
                { text: "Inactivo", value: 0 },
            ],
        };
    },
    computed: {
        ...mapState("pacientes", ["total"]),
        ...mapGetters("pacientes", ["pacientes", "isLoading"]),
        headers() {
            return [
                { title: "Nombre Completo", key: "nombres" },
                { title: "Documento", key: "numero_documento" },
                { title: "Celular", key: "celular" },
                { title: "Correo", key: "correo" },
                { title: "Acciones", key: "acciones", align: "center", sortable: false },
            ];
        },
    },
    methods: {
        ...mapActions("pacientes", ["fetchPacientes", "savePaciente", "deletePaciente", "buscarPacientePorDni"]),

        async cargarPacientes() {
            const { page, itemsPerPage } = this.options;
            try {
                await this.fetchPacientes({
                    page,
                    per_page: itemsPerPage,
                    search: this.search || null,
                });
            } catch (e) {
                this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
            }
        },
        abrirDialogoAgregar() {
            this.dialogAgregar = true;
            this.nuevoPaciente = {
                nombres: "",
                apellido_paterno: "",
                apellido_materno: "",
                numero_documento: "",
                fecha_nacimiento: "",
                sexo: "",
                celular: "",
                correo: "",
                direccion: "",
                puesto: "",
                nro_registro: "",
                ubigeo_nacimiento: "",
            };
        },
        async guardarPaciente() {
            try {
                await this.savePaciente(this.nuevoPaciente);
                this.dialogAgregar = false;
                this.cargarPacientes();
                this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Paciente guardado exitosamente");
            } catch (e) {
                this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
            }
        },
        verDetalle(item) {
            this.$router.push({ name: "detallepaciente", params: { id: item.id } });
        },
        confirmarEliminar(item) {
            this.pacienteAEliminar = item;
            this.showDeleteDialog = true;
        },
        async eliminarPaciente() {
            try {
                await this.deletePaciente(this.pacienteAEliminar.id);
                this.showDeleteDialog = false;
                this.pacienteAEliminar = null;
                this.cargarPacientes();
                this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Paciente eliminado");
            } catch (e) {
                this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
            }
        },
        cancelarEliminar() {
            this.showDeleteDialog = false;
            this.pacienteAEliminar = null;
        },
        async buscarPorDocumento() {
            const dni = this.nuevoPaciente.numero_documento;

            if (!dni || dni.length < 3) {
                this.pacienteEncontrado = false;
                return;
            }

            try {
                const result = await this.buscarPacientePorDni(dni);

                if (result && result.data) {
                    this.nuevoPaciente = { ...result.data };
                    this.pacienteEncontrado = true;

                    this.$store.commit("SHOW_SUCCESS_SNACKBAR",
                        result.origen === "paciente"
                            ? "Paciente existente cargado desde base de datos principal."
                            : "Paciente obtenido desde Mediweb. Puedes completar o actualizar los datos."
                    );
                } else {
                    this.pacienteEncontrado = false;
                }
            } catch (e) {
                this.pacienteEncontrado = false;
                this.$store.commit("SHOW_ERROR_SNACKBAR", "Error al buscar el paciente");
            }
        }

    },
    watch: {
        search: debounce(function () {
            this.cargarPacientes();
        }, 500),
    },
    created() {
        this.cargarPacientes();
    },
};
</script>