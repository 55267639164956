<template>
    <div class="pa-4 text-center">
      <v-dialog :model-value="isVisible" @update:model-value="updateVisibility" max-width="700" persistent>
        <v-card prepend-icon="mdi-calendar-clock" title="Programar Reincorporación" class="pa-4">
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="text-center">
                  <p class="text-h5">{{ pacienteNombre.toUpperCase() }}</p>
                  <p class="text-caption">Documento: {{ documento }}</p>
                </div>
              </v-col>
            </v-row>
  
            <!-- Historial de Reincorporaciones -->
            <v-row v-if="programacionesLocal && programacionesLocal.length">
              <v-col cols="12">
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Fecha</th>
                      <th class="text-left">Hora</th>
                      <th class="text-left">Registrado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(prog, index) in programacionesLocal" :key="index">
                      <td>{{ moment(prog.fecha_hora).format('DD-MM-YYYY') }}</td>
                      <td>{{ moment(prog.fecha_hora).format('HH:mm') }}</td>
                      <td>{{ moment(prog.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="text-caption text-grey">
                No hay reincorporaciones registradas.
              </v-col>
            </v-row>
  
            <!-- Campos para nueva reincorporación -->
            <v-row>
              <v-col cols="12" md="6">
                <v-date-input label="Fecha" v-model="form.fecha" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="form.hora" label="Hora" prepend-icon="mdi-clock-time-four-outline" readonly>
                  <v-menu
                    v-model="menuHora"
                    :close-on-content-click="false"
                    activator="parent"
                    transition="scale-transition"
                  >
                    <v-time-picker v-if="menuHora" v-model="form.hora" format="24hr" />
                  </v-menu>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="plain" text="Cerrar" @click="closeDialog">Cerrar</v-btn>
            <v-btn color="primary" variant="tonal" text="Guardar" @click="guardarReincorporacion">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { VDateInput } from 'vuetify/labs/VDateInput';
  import { VTimePicker } from 'vuetify/labs/VTimePicker';
  import moment from 'moment';
  
  export default {
    name: 'ModalReincorporaciones',
    components: {
      VDateInput,
      VTimePicker,
    },
    props: {
      isVisible: Boolean,
      pacienteNombre: String,
      documento: String,
      descansoId: Number,
      programaciones: Array,
    },
    data: () => ({
      moment,
      menuHora: false,
      form: {
        fecha: null,
        hora: null,
      },
      programacionesLocal: [],
    }),
    watch: {
      programaciones: {
        handler(newVal) {
          this.programacionesLocal = [...newVal];
        },
        immediate: true,
      },
    },
    methods: {
      updateVisibility(val) {
        this.$emit('update:isVisible', val);
      },
      closeDialog() {
        this.$emit('update:isVisible', false);
      },
      async guardarReincorporacion() {
        if (!this.form.fecha || !this.form.hora) {
          this.$store.commit('SHOW_ERROR_SNACKBAR', 'Debe completar fecha y hora');
          return;
        }
  
        try {
          const fechaFormateada = moment(this.form.fecha).format('YYYY-MM-DD');
  
          await this.$store.dispatch('descansosMedicos/guardarReincorporacion', {
            id: this.descansoId,
            fecha: fechaFormateada,
            hora: this.form.hora,
          });
  
          // Agrega el nuevo registro a la tabla local
          this.programacionesLocal.unshift({
            fecha_hora: `${fechaFormateada} ${this.form.hora}`,
            created_at: moment().format(),
          });
  
          this.$store.commit('SHOW_SUCCESS_SNACKBAR', 'Reincorporación guardada correctamente');
  
          // Limpieza
          this.form.fecha = null;
          this.form.hora = null;
  
          // Evento opcional para refrescar padre
          this.$emit('reincorporacion-creada');
        } catch (e) {
          this.$store.commit('SHOW_ERROR_SNACKBAR', e.message);
        }
      },
    },
  };
  </script>
  