<template>
  <v-card class="mt-0">
    <v-card-title class="text-h6">
      ASIGNAR DESCANSOS MÉDICOS A USUARIOS
    </v-card-title>
    <v-card-text>
      <!-- Fila para seleccionar la fecha y buscar asignaciones -->
      <v-row class="mb-3" align="center">
        <v-col cols="8">
          <v-date-input v-model="selectedDate" label="Seleccionar fecha" density="compact" />
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="buscarAsignacionesFuturas">
            Buscar asignaciones
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <!-- Izquierda: Descansos Médicos NO Asignados -->
        <v-col cols="7">
          <v-card class="pa-3 mt-0">
            <v-card-title class="text-center">
              DESCANSOS MÉDICOS NO ASIGNADOS
            </v-card-title>
            <draggable v-model="descansosLocal" group="descansos" item-key="id" @change="moverDescanso"
              :fallback-on-body="true" :force-fallback="true">
              <template #item="{ element }">
                <div>
                  <v-card class="my-2" color="#1F7087" variant="tonal" elevation="2">
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="text-h5">
                          {{ element.descansomedico?.paciente?.full_name || 'Sin paciente' }}
                        </v-card-title>
                        <v-card-subtitle>
                          {{ element.descansomedico?.inicio
                            ? moment(element.descansomedico?.inicio).format("DD-MM-YYYY")
                            : 'Sin fecha'
                          }} -
                          {{ element.descansomedico?.fin
                            ? moment(element.descansomedico?.fin).format("DD-MM-YYYY")
                            : 'Sin fecha'
                          }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                          Motivo:
                          {{
                            element.descansomedico?.motivo && element.descansomedico.motivo.length
                              ? element.descansomedico.motivo.map(m => m.descripcion).join(', ')
                              : 'Sin motivo'
                          }}
                        </v-card-subtitle>
                        <v-card-subtitle style="max-height: 60px; overflow-y: auto;">
                          Diagnósticos:
                          {{
                            element.descansomedico?.diagnosticos && element.descansomedico.diagnosticos.length
                              ? element.descansomedico.diagnosticos
                                .map(d => d.codigo + " " + d.descripcion + (d.pivot && d.pivot.detalle_cie10 ? " (" +
                                  d.pivot.detalle_cie10 + ")" : ""))
                                .join(", ")
                              : "Sin diagnósticos"
                          }}
                        </v-card-subtitle>


                        <v-card-subtitle>
                          Empresa:
                          {{
                            element.descansomedico?.paciente?.empresa?.id === 1
                              ? "Sociedad Minera Cerro Verde"
                              : "Contratista"
                          }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                          Total días:
                          {{
                            element.descansomedico?.inicio && element.descansomedico?.fin
                              ? moment(element.descansomedico.fin).diff(moment(element.descansomedico.inicio), "days") + 1
                          : "N/A"
                          }}
                        </v-card-subtitle>

                      </div>
                      <v-avatar class="ma-3" rounded="0" size="80">
                        <v-img :src="ruta + element.descansomedico?.paciente?.numero_documento" alt="Foto del paciente"
                          contain></v-img>
                      </v-avatar>
                    </div>
                  </v-card>
                </div>
              </template>
            </draggable>
          </v-card>
        </v-col>

        <!-- Derecha: Usuarios (con buscador y drag & drop de asignaciones) -->
        <v-col cols="5">
          <v-card class="pa-3 mt-0">
            <v-card-title class="text-center">
              USUARIOS
            </v-card-title>
            <!-- Buscador de nombres -->
            <v-text-field v-model="searchUsuario" label="Buscar usuario" density="compact"
              prepend-inner-icon="mdi-magnify" clearable class="mb-3" />
            <v-row>
              <v-col v-for="usuario in usuariosFiltrados" :key="usuario.id" cols="12">
                <v-card class="user-dropzone pa-2 mt-0">
                  <v-card-title class="d-flex align-center justify-center">
                    <v-chip color="green" variant="flat" small class="mr-2">
                      {{ asignaciones[usuario.id] ? asignaciones[usuario.id].length : 0 }}
                    </v-chip>
                    <v-avatar class="mr-2" size="40">
                      <v-img :src="getUserImage(usuario)" alt="Foto de usuario" cover></v-img>
                    </v-avatar>
                    <span>
                      {{ usuario.nombres }}
                      {{ usuario.apellido_paterno || "" }}
                      {{ usuario.apellido_materno || "" }}
                    </span>
                  </v-card-title>
                  <draggable v-model="asignaciones[usuario.id]" group="descansos" item-key="id" @change="moverDescanso"
                    :fallback-on-body="true" :force-fallback="true">
                    <template #item="{ element }">
                      <div>
                        <v-card class="mb-2 mt-0" color="#1F7087" variant="tonal" elevation="2">
                          <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                              <v-card-title class="text-h5">
                                {{ element.descansomedico?.paciente?.full_name || 'Sin paciente' }}
                              </v-card-title>
                              <v-card-subtitle>
                                {{ element.descansomedico?.inicio
                                  ? moment(element.descansomedico?.inicio).format("DD-MM-YYYY")
                                  : 'Sin fecha'
                                }} -
                                {{ element.descansomedico?.fin
                                  ? moment(element.descansomedico?.fin).format("DD-MM-YYYY")
                                  : 'Sin fecha'
                                }}
                              </v-card-subtitle>
                              <v-card-subtitle>
                                Motivo:
                                {{
                                  element.descansomedico?.motivo && element.descansomedico.motivo.length
                                    ? element.descansomedico.motivo.map(m => m.descripcion).join(', ')
                                    : 'Sin motivo'
                                }}
                              </v-card-subtitle>
                              <v-card-subtitle class="text-truncate" style="max-width: 100%; overflow: hidden;">
                                Diagnósticos:
                                <v-tooltip location="top">
                                  <template #activator="{ props }">
                                    <span v-bind="props" class="text-truncate"
                                      style="display: inline-block; max-width: 300px;">
                                      {{
                                        element.descansomedico?.diagnosticos && element.descansomedico.diagnosticos.length
                                          ? element.descansomedico.diagnosticos
                                            .map(d => d.codigo + " " + d.descripcion)
                                            .join(", ")
                                          : "Sin diagnósticos"
                                      }}
                                    </span>
                                  </template>
                                  <span>
                                    {{
                                      element.descansomedico?.diagnosticos
                                        ?.map(d => d.codigo + " " + d.descripcion + (d.pivot?.detalle_cie10 ? `
                                    (${d.pivot.detalle_cie10})` : ""))
                                        .join(", ")
                                    }}
                                  </span>
                                </v-tooltip>
                              </v-card-subtitle>

                              <v-card-subtitle>
                                Empresa:
                                {{
                                  element.descansomedico?.paciente?.empresa?.id === 1
                                    ? "Sociedad Minera Cerro Verde"
                                    : "Contratista"
                                }}
                              </v-card-subtitle>
                              <v-card-subtitle>
                                Total días:
                                {{
                                  element.descansomedico?.inicio && element.descansomedico?.fin
                                    ? moment(element.descansomedico.fin).diff(moment(element.descansomedico.inicio), "days")
                                    + 1
                                    : "N/A"
                                }}
                              </v-card-subtitle>

                            </div>
                            <v-avatar class="ma-3" rounded="0" size="80">
                              <v-img :src="ruta + element.descansomedico?.paciente?.numero_documento"
                                alt="Foto del paciente" contain></v-img>
                            </v-avatar>
                          </div>
                        </v-card>
                      </div>
                    </template>
                  </draggable>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Botón Floating Action Button usando el componente de Vuetify -->
    <v-fab app color="primary" location="top right" size="large" icon @click="guardarAsignaciones">
      <v-icon>mdi-content-save</v-icon>
    </v-fab>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      moment,
      ruta: process.env.VUE_APP_API_URL + "/api/mostrarFotoMediweb/",
      descansosLocal: [],
      asignaciones: {},
      searchUsuario: "",
      selectedDate: new Date(),
    };
  },
  computed: {
    ...mapState("descansosMedicos", ["distribucionDescansos"]),
    descansos() {
      return this.distribucionDescansos;
    },
    usuarios() {
      return this.$store.state.usuarios.data;
    },
    usuariosFiltrados() {
      if (!this.searchUsuario) return this.usuarios;
      return this.usuarios.filter((usuario) => {
        const fullName = `${usuario.nombres} ${usuario.apellido_paterno || ""} ${usuario.apellido_materno || ""}`;
        return fullName.toLowerCase().includes(this.searchUsuario.toLowerCase());
      });
    },
  },
  watch: {
    distribucionDescansos: {
      handler(nuevaDistribucion) {
        // Separa los descansos sin asignar
        this.descansosLocal = nuevaDistribucion.filter(
          (descanso) => descanso.usuarios.length === 0
        );
        // Inicializa el array de asignaciones para cada usuario
        this.usuarios.forEach((usuario) => {
          this.asignaciones[usuario.id] = [];
        });
        // Asigna descansos a cada usuario, si ya estaban asignados
        nuevaDistribucion.forEach((descanso) => {
          descanso.usuarios.forEach((usuario) => {
            this.asignaciones[usuario.id].push(descanso);
          });
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("descansosMedicos", [
      "fetchDistribucion",
      "asignarDescansos",
    ]),
    ...mapActions("usuarios", ["fetchUsuariosDistribucion"]),
    moverDescanso(event) {
      if (event.added) {
        console.log("Descanso añadido:", event.added.element);
      }
      if (event.removed) {
        console.log("Descanso removido:", event.removed.element);
      }
    },
    async buscarAsignacionesFuturas() {
      try {
        const dateString = moment(this.selectedDate).format("YYYY-MM-DD");
        await this.fetchDistribucion(dateString);
      } catch (error) {
        console.error("Error al buscar asignaciones por fecha:", error);
      }
    },
    async guardarAsignaciones() {
      try {
        const payload = Object.keys(this.asignaciones).map((userId) => ({
          usuario_id: parseInt(userId),
          programaciones: this.asignaciones[userId].map((d) => ({
            programacion_id: d.id,
          })),
        }));
        await this.asignarDescansos(payload);
        this.$store.commit(
          "SHOW_SUCCESS_SNACKBAR",
          "Asignaciones guardadas exitosamente"
        );
      } catch (error) {
        console.error("Error al guardar asignaciones:", error);
        this.$store.commit(
          "SHOW_ERROR_SNACKBAR",
          "Error al guardar asignaciones"
        );
      }
    },
    getUserImage(usuario) {
      if (usuario.especialidad_usuario_id === 2) {
        const cxpSinCeros = usuario.cxp.replace(/^0+/, ""); // elimina ceros a la izquierda
        return `https://aplicaciones.cmp.org.pe/conoce_a_tu_medico/fotos/${cxpSinCeros}.jpg`;
      } else if (usuario.especialidad_usuario_id === 3) {
        return `https://www.cep.org.pe/fotos/${usuario.numero_documento}.jpg`;
      }
      return null;
    }

  },
  async created() {
    await this.fetchUsuariosDistribucion();
    // Inicializar asignaciones para cada usuario
    this.usuarios.forEach((usuario) => {
      if (!this.asignaciones[usuario.id]) {
        this.asignaciones[usuario.id] = [];
      }
    });
    // Cargar asignaciones para la fecha por defecto
    await this.fetchDistribucion(moment(this.selectedDate).format("YYYY-MM-DD"));
  },
};
</script>

<style scoped>
.user-dropzone {
  border: 2px dashed #90a4ae;
  min-height: 150px;
  border-radius: 8px;
  padding: 10px;
}

.v-card {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
