<template>
  <v-card>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-btn icon @click="toggleEdit">
        <v-icon>{{ editable ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h5>INFORMACIÓN DEL PACIENTE</h5>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <!-- Requiere EMA -->
        <v-tooltip :text="paciente?.requiereema ? 'Requiere EMA' : 'No requiere EMA'">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-calendar-month" v-bind="props" :color="paciente?.requiereema ? 'red' : 'green'"
              :class="paciente?.requiereema ? 'blinking ml-5' : 'ml-5'" @click="handleEma"></v-btn>
          </template>
        </v-tooltip>
        <!-- Avisar a Priscilla -->
        <v-tooltip :text="paciente?.avisarpricsilla ? 'Avisar a Priscilla' : 'No avisar a Priscilla'">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-account-voice" v-bind="props" :color="paciente?.avisarpricsilla ? 'red' : 'green'"
              :class="paciente?.avisarpricsilla ? 'blinking' : ''" @click="notifyPriscilla"></v-btn>
          </template>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-card-text v-if="paciente">
      <v-img class="align-end" contain height="200" :src="ruta + paciente.numero_documento"></v-img>
      <v-text-field v-model="editablePaciente.nombres" :readonly="!editable" density="compact" hide-details
        label="NOMBRES"></v-text-field>
      <v-text-field v-model="editablePaciente.apellido_paterno" :readonly="!editable" density="compact" hide-details
        label="APELLIDO PATERNO"></v-text-field>
      <v-text-field v-model="editablePaciente.apellido_materno" :readonly="!editable" density="compact" hide-details
        label="APELLIDO MATERNO"></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="editablePaciente.numero_documento" :readonly="!editable" density="compact" class="mr-2"
          hide-details label="DNI"></v-text-field>
        <v-text-field v-model="editablePaciente.nro_registro" :readonly="!editable" density="compact" hide-details
          label="REGISTRO"></v-text-field>
      </div>
      <v-text-field v-model="editablePaciente.celular" :readonly="!editable" density="compact" hide-details
        label="CELULAR"></v-text-field>
      <v-text-field v-model="editablePaciente.correo" :readonly="!editable" density="compact" hide-details
        label="CORREO" append-inner-icon="mdi-email"
        @click:append-inner="enviarCorreo(editablePaciente.correo)"></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="editablePaciente.fecha_nacimiento" :readonly="!editable" density="compact" hide-details
          type="date" label="F.NAC"></v-text-field>
        <v-text-field v-model="editablePaciente.edad" density="compact" hide-details label="EDAD"
          readonly></v-text-field>
        <v-text-field v-model="editablePaciente.sexo" density="compact" hide-details label="SEXO"></v-text-field>
      </div>
      <v-text-field v-model="editablePaciente.posicion.descripcion" readonly density="compact" hide-details rea
        label="POSICIÓN"></v-text-field>
      <v-text-field v-model="editablePaciente.posicion.superintendencia.descripcion" density="compact" hide-details
        readonly label="SUPERINTENDENCIA"></v-text-field>
      <v-text-field v-model="editablePaciente.posicion.superintendencia.gerencia.descripcion" density="compact" readonly
        hide-details label="GERENCIA"></v-text-field>
      <v-text-field v-model="editablePaciente.posicion.superintendencia.gerencia.gerencia_general.descripcion" readonly
        density="compact" hide-details label="GERENCIA GENERAL"></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DatosTrabajador",
  data() {
    return {
      editable: false,
      editablePaciente: null,
      ruta: process.env.VUE_APP_API_URL + "/api/mostrarFotoMediweb/",
    };
  },
  computed: {
    paciente() {
      return this.$store.state.descansosMedicos.descansoSeleccionado?.paciente || null;
    },
  },
  watch: {
    paciente: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.editablePaciente = JSON.parse(JSON.stringify(newVal));
          this.calculateAge(); // Calcular la edad inicialmente
        }
      },
    },
    "editablePaciente.fecha_nacimiento": {
      handler() {
        this.calculateAge(); // Calcular la edad cuando cambia la fecha de nacimiento
      },
      immediate: true,
    },
  },
  methods: {
    toggleEdit() {
      this.editable = !this.editable;
      if (!this.editable) {
        // Guardar los cambios al desactivar edición
        this.saveChanges();
      }
    },
    enviarCorreo(correo) {
      window.location.href = `mailto:${correo}?subject=CORREO DE DESCANSOS MÉDICOS&body=Estimado Paciente.`;
    },
    async saveChanges() {
      try {
        const updatedData = { ...this.editablePaciente };
        await this.$store.dispatch("pacientes/savePaciente", updatedData);
        this.$emit("update-success"); // Emitir un evento en caso de éxito
      } catch (error) {
        console.error("Error al guardar los cambios:", error.message);
      }
    },
    calculateAge() {
      if (this.editablePaciente && this.editablePaciente.fecha_nacimiento) {
        const birthDate = new Date(this.editablePaciente.fecha_nacimiento);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        this.editablePaciente.edad = age >= 0 ? age : null;
      }
    },
    handleEma() {
      console.log("Requiere EMA");
    },
    notifyPriscilla() {
      console.log("Avisar a Priscilla");
    },
  },
};
</script>

<style scoped>
.blinking {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
