<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4" density="compact">
      <!-- Botón para abrir el modal -->
      <v-btn class="ml-2" color="orange" variant="tonal" @click="modalAmpliacion = true">
        <v-icon left>mdi-plus</v-icon>
        Ampliación
      </v-btn>
      <v-spacer></v-spacer>
      <h5 class="white--text">HISTORIAL DE DESCANSOS MÉDICOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-timeline v-if="eventosOrdenados.length" side="end">
        <template v-for="(evento, index) in eventosOrdenados" :key="index">
          <!-- DESCANSO MÉDICO -->
          <v-timeline-item v-if="evento.tipo === 'descanso'"
            :class="{ 'evento-seleccionado': eventoSeleccionado === index }" dot-color="blue" class="cursor-pointer"
            size="small" :fill-dot="eventoSeleccionado !== index" @click="seleccionarDescanso(evento, index)">
            <template v-slot:opposite>
              <div>
                <div><strong>DESCANSO MÉDICO</strong></div>
                <div><small>Estado: {{ evento.estado || 'Sin estado' }}</small></div>
                <div>
                  <v-chip small color="primary" class="ma-1">
                    {{ calcularDias(evento.inicio, evento.fin) }} días
                  </v-chip>
                </div>
              </div>
            </template>
            <div class="d-flex flex-column">
              <div>
                <strong class="me-4">
                  {{ formatFechas(evento.inicio, evento.fin) }}
                </strong>
              </div>

              <!-- Diagnósticos con Tooltip -->
              <div class="text-caption">
                <span v-if="evento.diagnosticos && evento.diagnosticos.length">
                  <span v-for="(diagnostico, dIndex) in evento.diagnosticos" :key="dIndex" class="diagnostico-item">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="text-caption font-weight-bold">
                          {{ diagnostico.codigo }}
                        </span>
                      </template>
                      <span>{{ diagnostico.descripcion }}</span>
                    </v-tooltip>
                    <span v-if="dIndex !== evento.diagnosticos.length - 1">, </span>
                  </span>
                </span>
                <span v-else>No especificado</span>
              </div>
            </div>
          </v-timeline-item>

          <!-- AMPLIACIONES -->
          <v-timeline-item v-else-if="evento.tipo === 'ampliacion'" dot-color="orange" size="x-small">
            <template v-slot:opposite>
              <div>
                <div><strong>AMPLIACIÓN</strong></div>
                <div>
                  <v-chip small color="orange" class="ma-1">
                    {{ calcularDias(evento.inicio, evento.fin) }} días
                  </v-chip>
                </div>
              </div>
            </template>
            <div class="d-flex flex-column">
              <div>
                <strong>{{ formatFechas(evento.inicio, evento.fin) }}</strong>
                <v-btn icon="mdi-delete" size="small" variant="text" color="red"
                  @click="confirmarEliminarAmpliacion(evento)">
                </v-btn>
              </div>
              <!-- Diagnósticos con Tooltip -->
              <div class="text-caption">
                <span v-if="evento.diagnosticos && evento.diagnosticos.length">
                  <span v-for="(diagnostico, dIndex) in evento.diagnosticos" :key="dIndex" class="diagnostico-item">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="text-caption font-weight-bold">
                          {{ diagnostico.codigo }}
                        </span>
                      </template>
                      <span>{{ diagnostico.descripcion }} - {{ diagnostico.pivot.detalle_cie10 }}</span>
                    </v-tooltip>
                    <span v-if="dIndex !== evento.diagnosticos.length - 1">, </span>
                  </span>
                </span>
                <span v-else>No especificado</span>
              </div>
            </div>
          </v-timeline-item>
        </template>
      </v-timeline>
    </v-card-text>
    <!-- MODAL PARA AMPLIACIÓN -->
    <v-dialog v-model="modalAmpliacion" max-width="500px">
      <v-card>
        <v-card-title class="headline">Nueva Ampliación</v-card-title>
        <v-card-text>
          <v-date-input v-model="rangoFechas" label="Seleccione el Rango de Fechas" :min="minFechaAmpliacion" max-width="368" multiple="range"
            @update:modelValue="actualizarFechas" />
          <v-autocomplete v-model="diagnosticosAmpliacion" :items="cie10List" item-title="descripcion" item-value="id"
            label="Seleccione Diagnósticos (opcional)" prepend-icon="mdi-hospital-box" multiple chips closable-chips
            density="compact" clearable hide-details v-model:search="searchCIE10" :loading="loadingDiagnosticos"
            @update:search="buscarDiagnosticos" />
          <small class="text-caption text-grey-darken-1">
            Puedes dejar los diagnósticos vacíos si aún no están definidos.
          </small>

          <div v-if="diagnosticosAmpliacion.length">
            <h6 class="mt-3">Detalles por Diagnóstico (opcional)</h6>
            <div v-for="(diagnosticoId, index) in diagnosticosAmpliacion" :key="index">
              <v-textarea v-model="detallesDiagnosticos[diagnosticoId]" label="Detalle del diagnóstico"
                prepend-icon="mdi-note-text" rows="2" auto-grow density="compact" hide-details />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="modalAmpliacion = false">Cancelar</v-btn>
          <v-btn color="primary" :disabled="!fechaInicio || !fechaFin" @click="guardarAmpliacion">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEliminarAmpliacion" max-width="400px">
      <v-card>
        <v-card-title class="headline">¿Eliminar Ampliación?</v-card-title>
        <v-card-text>¿Estás seguro de que deseas eliminar esta ampliación?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogEliminarAmpliacion = false">Cancelar</v-btn>
          <v-btn color="red" text @click="eliminarAmpliacion">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "DescansosMedicosTimeline",
  data() {
    return {
      moment,
      modalAmpliacion: false,
      rangoFechas: null,
      fechaInicio: "",
      fechaFin: "",
      diagnosticosAmpliacion: [],
      cie10List: [],
      searchCIE10: "",
      loadingDiagnosticos: false,
      eventoSeleccionado: null,
      detallesDiagnosticos: {},
      ampliacionAEliminar: null,
      dialogEliminarAmpliacion: false,
    };
  },
  computed: {
    descansoSeleccionadoId() {
      return this.$store.state.descansosMedicos.descansoSeleccionado?.id || null;
    },
    descansosMedicos() {
      const paciente = this.$store.state.descansosMedicos.descansoSeleccionado?.paciente;
      return paciente && Array.isArray(paciente.descansos_medicos)
        ? paciente.descansos_medicos.map(item => ({
          ...item,
          ampliacion: item.ampliacion || []
        }))
        : [];
    },
    minFechaAmpliacion() {
      const descanso = this.$store.state.descansosMedicos.descansoSeleccionado;
      return descanso?.inicio ? new Date(descanso.inicio) : null;
    },
    eventosOrdenados() {
      const eventos = [];

      this.descansosMedicos.forEach((descanso) => {
        // Insertar ampliaciones antes del descanso
        if (descanso.ampliacion?.length) {
          descanso.ampliacion.forEach((amp) => {
            eventos.push({
              tipo: 'ampliacion',
              descansoId: descanso.id,
              inicio: amp.fecha_inicio,
              fin: amp.fecha_fin,
              diagnosticos: amp.diagnosticos,
              id: amp.id, // para eliminar
            });
          });
        }

        // Luego el descanso principal
        eventos.push({
          tipo: 'descanso',
          id: descanso.id,
          inicio: descanso.inicio,
          fin: descanso.fin,
          estado: descanso.estado?.descripcion || 'Sin estado',
          diagnosticos: descanso.diagnosticos,
          bitacoras: descanso.bitacoras,
        });
      });

      // Ya están ordenadas ampliaciones sobre su descanso, ahora solo ordenamos por fecha de descanso descendente
      return eventos.sort((a, b) => {
        const fechaA = a.tipo === 'descanso' ? a.inicio : a.fin;
        const fechaB = b.tipo === 'descanso' ? b.inicio : b.fin;
        return new Date(fechaB) - new Date(fechaA);
      });
    }


  },
  methods: {
    ...mapActions("descansosMedicos", ["fetchSubcategoriasCIE10"]),
    seleccionarDescanso(descanso, index) {
      this.eventoSeleccionado = index;
      this.$store.dispatch("descansosMedicos/fetchBitacorasEvento", descanso.id);
    },
    formatFechas(inicio, fin) {
      if (inicio && fin) {
        return `${moment(inicio).format("DD-MMM-YYYY")} - ${moment(fin).format("DD-MMM-YYYY")}`;
      } else if (inicio) {
        return `${moment(inicio).format("DD-MMM-YYYY")} - Sin fecha de fin`;
      } else if (fin) {
        return `Sin fecha de inicio - ${moment(fin).format("DD-MMM-YYYY")}`;
      } else {
        return "Fechas aún no definidas";
      }
    },
    calcularDias(inicio, fin) {
      if (inicio && fin) {
        const dias = moment(fin).diff(moment(inicio), 'days') + 1;
        return dias;
      }
      return 0;
    },
    actualizarFechas() {
      if (Array.isArray(this.rangoFechas) && this.rangoFechas.length) {
        const fechas = [...this.rangoFechas].sort((a, b) => new Date(a) - new Date(b));
        this.fechaInicio = fechas[0];
        this.fechaFin = fechas[fechas.length - 1]; // misma si es solo una
      } else {
        this.fechaInicio = "";
        this.fechaFin = "";
      }
    },
    async buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      try {
        const data = await this.fetchSubcategoriasCIE10(searchValue);
        this.cie10List = data.map((item) => ({
          id: item.id,
          descripcion: `${item.codigo} - ${item.descripcion}`,
        }));
      } catch (e) {
        console.error("Error al buscar diagnósticos CIE-10:", e.message);
      } finally {
        this.loadingDiagnosticos = false;
      }
    },
    confirmarEliminarAmpliacion(ampliacion) {
      this.ampliacionAEliminar = ampliacion;
      this.dialogEliminarAmpliacion = true;
    },

    async eliminarAmpliacion() {
      if (!this.ampliacionAEliminar?.id) return;

      try {
        await this.$store.dispatch("descansosMedicos/eliminarAmpliacion", this.ampliacionAEliminar.id);
        this.$store.dispatch("descansosMedicos/fetchDescansoById", this.descansoSeleccionadoId);
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Ampliación eliminada correctamente.");
      } catch (error) {
        console.error("Error al eliminar ampliación:", error);
        this.$store.commit("SHOW_ERROR_SNACKBAR", "No se pudo eliminar la ampliación.");
      } finally {
        this.dialogEliminarAmpliacion = false;
        this.ampliacionAEliminar = null;
      }
    },
    guardarAmpliacion() {
      if (!this.fechaInicio || !this.fechaFin) {
        alert("Por favor seleccione un rango de fechas.");
        return;
      }

      const ultimoDescanso = this.descansosMedicos[this.descansosMedicos.length - 1];
      if (!ultimoDescanso) {
        alert("No hay un descanso médico para ampliar.");
        return;
      }

      if (!this.fechaInicio || !this.fechaFin) {
        alert("Por favor seleccione al menos una fecha.");
        return;
      }

      const payload = {
        descansomedico_id: ultimoDescanso.id,
        fecha_inicio: moment(this.fechaInicio).format('YYYY-MM-DD'),
        fecha_fin: moment(this.fechaFin).format('YYYY-MM-DD'),
        diagnosticos: this.diagnosticosAmpliacion.map(id => ({
          id,
          detalle_cie10: this.detallesDiagnosticos[id] || "",
        })),
      };

      this.$store
        .dispatch("descansosMedicos/guardarAmpliacion", payload)
        .then(() => {
          this.modalAmpliacion = false;
          this.rangoFechas = null;
          this.fechaInicio = "";
          this.fechaFin = "";
          this.diagnosticosAmpliacion = [];
          this.detallesDiagnosticos = {};
          this.$store.dispatch("descansosMedicos/fetchDescansoById", this.descansoSeleccionadoId);
          this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Ampliación registrada correctamente.");
        })
        .catch((error) => {
          console.error("Error al guardar la ampliación:", error);
          this.$store.commit("SHOW_ERROR_SNACKBAR", "Error al guardar la ampliación.");
        });
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.evento-seleccionado {
  background-color: rgba(76, 175, 80, 0.2) !important;
  border-left: 4px solid #4caf50 !important;
  border-radius: 8px;
  padding: 8px;
}
</style>
