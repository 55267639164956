<template>
  <v-card>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-spacer></v-spacer>
      <h5 class="white--text">PROGRAMACIÓN REINCORPORACIÓN</h5>
      <v-spacer></v-spacer>
      <!-- Muestra Check Verde si está programada, X Roja si no lo está -->
      <v-btn icon :color="reincorporacionProgramada ? 'green' : 'red'">
        <v-icon>{{ reincorporacionProgramada ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-text-field
        label="Fecha"
        v-model="reincorporacion.fecha"
        readonly
        density="compact"
        hide-details
        prepend-icon="mdi-calendar"
      ></v-text-field>
      <v-text-field
        label="Hora"
        v-model="reincorporacion.hora"
        readonly
        density="compact"
        hide-details
        prepend-icon="mdi-clock"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "ProgramacionReincorporacion",
  computed: {
    // Accedemos directamente al estado del módulo "descansosMedicos"
    selectedDescanso() {
      return this.$store.state.descansosMedicos.descansoSeleccionado;
    },
    // Extrae la programación de reincorporación (se toma el primer registro)
    reincorporacion() {
      if (
        this.selectedDescanso &&
        this.selectedDescanso.programacionreincorporacion &&
        this.selectedDescanso.programacionreincorporacion.length > 0
      ) {
        const prog = this.selectedDescanso.programacionreincorporacion[0];
        return {
          // Se formatea el campo "fecha_hora" para separar fecha y hora
          fecha: prog.fecha_hora ? moment(prog.fecha_hora).format('DD-MM-YYYY') : null,
          hora: prog.fecha_hora ? moment(prog.fecha_hora).format('hh:mm A') : ""
        };
      }
      return {
        fecha: null,
        hora: ""
      };
    },
    // Se considera que está programada si existen fecha y hora definidas
    reincorporacionProgramada() {
      return !!(this.reincorporacion.fecha && this.reincorporacion.hora);
    }
  }
};
</script>

<style scoped>
</style>
