<template>
  <v-card outlined class="mt-0">
    <v-card-title class="d-flex justify-space-between align-center">
      LISTADO DE REINCORPORACIONES PRESENCIALES
    </v-card-title>
    <v-card-text>
      <v-data-table-server
        v-if="descansos"
        :headers="headers"
        :items="descansos"
        :loading="isLoading"
        v-model:items-per-page="itemsPerPage"
        v-model:options="options"
        @update:options="cargarData"
        :items-length="totalItems"
        density="compact"
        class="elevation-0"
        :items-per-page-options="itemsPorPagina"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ moment(item.created_at).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.inicio_fin`]="{ item }">
          {{
            item.inicio && item.fin
              ? moment(item.inicio).format('DD/MM/YYYY') + ' - ' + moment(item.fin).format('DD/MM/YYYY')
              : item.inicio
                ? moment(item.inicio).format('DD/MM/YYYY') + ' - Sin fecha de fin'
                : item.fin
                  ? 'Sin fecha de inicio - ' + moment(item.fin).format('DD/MM/YYYY')
                  : 'Fechas sin definir'
          }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip :color="item.estado.color" size="small">
            {{ item.estado.descripcion }}
          </v-chip>
        </template>
        <template v-slot:[`item.programacionreincorporacion`]="{ item }">
          <v-chip :color="getColor(item)" size="small">
            {{ getDescripcion(item) }}
          </v-chip>
        </template>
        <template v-slot:[`item.ver`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  @click="abrirDialogoAptitud(item)"
                  density="compact"
                  variant="text"
                  icon="mdi-check"
                ></v-btn>
              </template>
              <span>Aptitud y Comentario</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <td>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="params.queryFechaInicio"
                    hide-details
                    label="Fecha Inicio"
                    type="date"
                    class="ma-2"
                    density="compact"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="params.queryFechaFin"
                    hide-details
                    label="Fecha Fin"
                    type="date"
                    class="ma-2"
                    density="compact"
                  ></v-text-field>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-text-field
                v-model="params.queryPaciente"
                hide-details
                placeholder="Buscar Paciente"
                type="text"
                class="ma-2"
                density="compact"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="params.queryNumeroDocumento"
                hide-details
                placeholder="Buscar DNI"
                type="text"
                class="ma-2"
                density="compact"
              ></v-text-field>
            </td>
            <td>
              <v-autocomplete
                v-model="params.queryDiagnosticos"
                :items="cie10List"
                item-title="descripcion"
                item-value="id"
                multiple
                return-object
                chips
                density="compact"
                hide-details
                clearable
                closable-chips
                v-model:search="searchCIE10"
                label="Buscar Diagnósticos"
                placeholder="Escriba para buscar..."
                :loading="loadingDiagnosticos"
                @update:search="buscarDiagnosticos"
              ></v-autocomplete>
            </td>
            <td>
              <v-select
                v-model="params.queryEstado"
                item-value="value"
                item-title="text"
                label="Buscar Estado"
                multiple
                class="ma-2"
                hide-details
                :items="estados"
                density="compact"
              ></v-select>
            </td>
          </tr>
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>

  <!-- Diálogo para actualizar aptitud -->
  <v-dialog v-model="dialogAptitud" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">Actualizar Aptitud y Comentario</span>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="newAptitud.aptitud"
          :items="calificacionesReincorporacion"
          item-title="descripcion"
          item-value="id"
          label="Aptitud"
          required
        ></v-select>
        <v-textarea v-model="newAptitud.comentario" label="Comentario" required></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cerrarDialogAptitud">Cancelar</v-btn>
        <v-btn color="blue" text @click="guardarAptitudComentario">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ListadoReincorporacionesPresenciales",
  data() {
    return {
      itemsPorPagina: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" }
      ],
      itemsPerPage: 10,
      search: null,
      moment,
      options: {},
      params: {
        queryFechaInicio: "",
        queryFechaFin: "",
        queryPaciente: "",
        queryNumeroDocumento: "",
        queryDiagnosticos: [],
        queryEstado: []
      },
      cie10List: [],
      loadingDiagnosticos: false,
      searchCIE10: "",
      estados: [
        { value: "1", text: "SEGUIMIENTO" },
        { value: "2", text: "FINALIZADO" }
      ],
      dialogAptitud: false,
      newAptitud: {
        aptitud: "",
        comentario: ""
      },
      selectedItemId: null,
    };
  },
  computed: {
    ...mapState("descansosMedicos", [
      "total",
      "isLoading",
      "calificacionesReincorporacion"
    ]),
    ...mapGetters("descansosMedicos", ["descansos"]),
    totalItems() {
      return this.total;
    },
    headers() {
      return [
        { title: "Fecha", align: "center", sortable: false, key: "created_at" },
        { title: "Paciente", align: "center", sortable: false, key: "paciente.full_name" },
        { title: "Número de Documento", align: "center", sortable: false, key: "paciente.numero_documento" },
        { title: "Inicio-Fin", align: "center", sortable: false, key: "inicio_fin" },
        { title: "Estado", align: "center", sortable: false, key: "estado" },
        { title: "Calificacion", align: "center", sortable: false, key: "programacionreincorporacion" },
        { title: "Acciones", align: "center", sortable: false, key: "ver" }
      ];
    }
  },
  methods: {
    ...mapActions("descansosMedicos", [
      "fetchReincorporacionesProgramadas", // ✅ nueva acción que usa la nueva ruta
      "searchPacientes",
      "fetchCalificacionesReincorporacion",
      "actualizarAptitudComentario"
    ]),
    cargarData() {
      const params = {
        queryFechaInicio: this.params.queryFechaInicio || null,
        queryFechaFin: this.params.queryFechaFin || null,
        queryPaciente: this.params.queryPaciente || null,
        queryNumeroDocumento: this.params.queryNumeroDocumento || null,
        queryDiagnosticos:
          this.params.queryDiagnosticos?.length > 0
            ? this.params.queryDiagnosticos.map(d => d.id)
            : null,
        queryEstado:
          this.params.queryEstado?.length > 0
            ? this.params.queryEstado.map(e => e.value)
            : null,
        itemsPerPage: this.itemsPerPage
      };
      this.fetchReincorporacionesProgramadas(params);
    },
    buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      this.$store.dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue)
        .then(data => {
          this.cie10List = data.map(item => ({
            id: item.id,
            descripcion: `${item.codigo} - ${item.descripcion}`
          }));
        })
        .finally(() => this.loadingDiagnosticos = false);
    },
    abrirDialogoAptitud(item) {
      this.selectedItemId = item.id;
      this.newAptitud.aptitud = "";
      this.newAptitud.comentario = "";
      this.dialogAptitud = true;
    },
    cerrarDialogAptitud() {
      this.dialogAptitud = false;
      this.newAptitud.aptitud = "";
      this.newAptitud.comentario = "";
    },
    async guardarAptitudComentario() {
      try {
        await this.actualizarAptitudComentario({
          id: this.selectedItemId,
          aptitud: this.newAptitud.aptitud,
          comentario: this.newAptitud.comentario
        });
        this.cargarData();
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Aptitud y comentario actualizados correctamente.");
        this.cerrarDialogAptitud();
      } catch (error) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", error.message);
      }
    },
    getColor(item) {
      const prog = item.programacionreincorporacion?.[0];
      if (!prog?.calificaciones?.length) return "orange";
      const cal = prog.calificaciones[0];
      return cal.id === 1 ? "green" : cal.id === 2 ? "red" : "primary";
    },
    getDescripcion(item) {
      const prog = item.programacionreincorporacion?.[0];
      return prog?.calificaciones?.[0]?.descripcion || "PENDIENTE";
    }
  },
  created() {
    this.cargarData();
    this.fetchCalificacionesReincorporacion();
  },
  watch: {
    "params.queryFechaInicio": "cargarData",
    "params.queryFechaFin": "cargarData",
    "params.queryPaciente": "cargarData",
    "params.queryNumeroDocumento": "cargarData",
    "params.queryEstado": "cargarData",
    "params.queryDiagnosticos": {
      handler: "cargarData",
      deep: true
    }
  }
};
</script>
