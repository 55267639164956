<template>
  <v-card>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-btn icon @click="dialog = true" class="white--text">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h5 class="white--text">ADJUNTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Descripción</th>
            <th class="text-center">Previsualizar</th>
            <th class="text-center">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in adjuntos" :key="item.id">
            <td class="text-left">{{ item.descripcion }}</td>
            <td class="text-center">
              <v-btn icon="mdi-eye" density="compact" @click="previewArchivo(item)">
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn icon="mdi-delete" density="compact" @click="confirmarEliminar(item.id)">
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>

    <!-- Modal para agregar adjuntos -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="text-h6">Agregar Adjunto</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="newAdjunto.descripcion" label="Descripción del archivo"
              prepend-icon="mdi-file-document" required :rules="[rules.required]"></v-text-field>

            <v-file-input v-model="newAdjunto.archivo" label="Seleccionar archivo" accept="image/*, application/pdf"
              prepend-icon="mdi-paperclip" show-size required :rules="[rules.required]"></v-file-input>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="guardarAdjunto">
            Guardar
          </v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Previsualización -->
    <v-dialog v-model="previewDialog" fullscreen persistent>
      <v-card class="mt-0">
        <v-card-title class="px-2">
          <span class="text-h6">Previsualización</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closePreview">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="previewFile.type === 'image'">
            <v-img :src="previewFile.url" contain height="calc(100vh - 64px)" width="100vw"></v-img>
          </div>
          <div v-else-if="previewFile.type === 'pdf'">
            <iframe :src="previewFile.url" style="width: 100vw; height: calc(100vh - 64px);" frameborder="0"></iframe>
          </div>
          <div v-else class="d-flex align-center justify-center" style="height: calc(100vh - 64px);">
            Archivo no compatible para previsualización.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Diálogo de Confirmación para Eliminar -->
    <v-dialog v-model="dialogEliminar" max-width="400">
      <v-card>
        <v-card-title class="text-h6">¿Eliminar adjunto?</v-card-title>
        <v-card-text>
          Esta acción eliminará el archivo permanentemente. ¿Deseas continuar?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="dialogEliminar = false">Cancelar</v-btn>
          <v-btn text color="red" @click="eliminarAdjuntoConfirmado">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AdjuntosDescansoMedico",
  data() {
    return {
      dialog: false,
      dialogEliminar: false,
      adjuntoAEliminar: null,
      previewDialog: false,
      valid: false,
      newAdjunto: {
        descripcion: "",
        archivo: null,
      },
      previewFile: {
        type: "",
        url: "",
      },
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
      },
    };
  },
  computed: {
    ...mapState("descansosMedicos", ["descansoSeleccionado"]),
    adjuntos() {
      return this.descansoSeleccionado?.adjuntos || [];
    },
  },
  methods: {
    ...mapActions("descansosMedicos", ["addAdjunto", "deleteAdjunto"]),

    async guardarAdjunto() {
      const form = this.$refs.form;
      if (form && form.validate()) {
        const formData = new FormData();
        formData.append("descanso_medico_id", this.descansoSeleccionado.id);
        formData.append("descripcion", this.newAdjunto.descripcion);
        formData.append("archivo", this.newAdjunto.archivo);

        try {
          await this.addAdjunto(formData);

          // Resetear formulario
          this.newAdjunto = { descripcion: "", archivo: null };
          form.resetValidation();
          this.dialog = false;

          this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Adjunto guardado correctamente.");
        } catch (error) {
          console.error("Error al guardar el adjunto:", error);
          this.$store.commit("SHOW_ERROR_SNACKBAR", "Error al guardar el adjunto.");
        }
      }
    },

    previewArchivo(item) {
      const extension = item.ruta.split(".").pop().toLowerCase();
      const baseURL = process.env.VUE_APP_API_URL || "http://localhost";
      const url = `${baseURL}/api/adjuntodescansomedico/${item.id}`;
      this.previewFile.type = ["jpg", "jpeg", "png"].includes(extension)
        ? "image"
        : extension === "pdf"
          ? "pdf"
          : "unknown";
      this.previewFile.url = url;
      this.previewDialog = true;
    },

    closePreview() {
      this.previewDialog = false;
      this.previewFile = { type: "", url: "" };
    },

    confirmarEliminar(adjuntoId) {
      this.adjuntoAEliminar = adjuntoId;
      this.dialogEliminar = true;
    },

    async eliminarAdjuntoConfirmado() {
      try {
        await this.deleteAdjunto(this.adjuntoAEliminar);
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Adjunto eliminado correctamente.");
      } catch (error) {
        console.error("Error al eliminar adjunto:", error);
        this.$store.commit("SHOW_ERROR_SNACKBAR", "No se pudo eliminar el adjunto.");
      } finally {
        this.dialogEliminar = false;
        this.adjuntoAEliminar = null;
      }
    },
  },
};
</script>

<style scoped>
/* Estilos personalizados opcionales */
</style>
