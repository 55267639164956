import axios from "axios";

const pacientes = {
  namespaced: true,
  state: {
    data: [],
    total: 0,
    pacienteSeleccionado: null,
    isLoading: false,
  },
  getters: {
    pacientes(state) {
      return state.data;
    },
    pacienteById: (state) => (id) => {
      return state.data.find((paciente) => paciente.id === id);
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    SET_PACIENTES(state, data) {
      state.data = data.data;
      state.total = data.total;
    },
    SET_PACIENTE_SELECCIONADO(state, data) {
      state.pacienteSeleccionado = data;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async fetchPacientes({ commit }, params) {
      commit("SET_LOADING", true);
      try {
        const { data } = await axios.get("/api/paciente", { params });
        commit("SET_PACIENTES", data);
      } catch (error) {
        console.error(
          "Error al obtener pacientes:",
          error.response?.data?.message || error.message
        );
        throw new Error(
          error.response?.data?.message || "Error al obtener pacientes"
        );
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchPacienteById({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/paciente/${id}`);
        commit("SET_PACIENTE_SELECCIONADO", data);
      } catch (error) {
        console.error(
          "Error al obtener el paciente:",
          error.response?.data?.message || error.message
        );
        throw new Error(
          error.response?.data?.message || "Error al obtener el paciente"
        );
      }
    },
    async savePaciente({ dispatch }, paciente) {
      try {
        if (paciente.id) {
          // Actualizar paciente existente
          await axios.put(`/api/paciente/${paciente.id}`, paciente);
        } else {
          // Crear nuevo paciente
          await axios.post("/api/paciente", paciente);
        }
        // Refrescar la lista después de guardar
        dispatch("fetchPacientes");
      } catch (error) {
        console.error(
          "Error al guardar el paciente:",
          error.response?.data?.message || error.message
        );
        throw new Error(
          error.response?.data?.message || "Error al guardar el paciente"
        );
      }
    },
    async deletePaciente({ dispatch }, id) {
      try {
        await axios.delete(`/api/paciente/${id}`);
        // Refrescar la lista después de eliminar
        dispatch("fetchPacientes");
      } catch (error) {
        console.error(
          "Error al eliminar el paciente:",
          error.response?.data?.message || error.message
        );
        throw new Error(
          error.response?.data?.message || "Error al eliminar el paciente"
        );
      }
    },
    async buscarPacientePorDni(_, dni) {
      try {
        const { data } = await axios.get("/api/pacientes/searchDni", {
          params: { dni },
        });
        return data; // contiene { data: {...}, origen: "paciente" | "mediweb" }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return null;
        }
        throw error;
      }
    },
    async searchPacientes(_, query) {
      const { data } = await axios.get("/api/paciente/search", {
        params: { query },
      });
      return data;
    },
  },
};

export default pacientes;
