<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title class="d-flex justify-space-between align-center">
        PROGRAMACIÓN DE REINCOPORACIONES PRESENCIALES SMCV
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table-server v-if="descansos" :headers="headers" :items="descansos" :loading="isLoading"
          v-model:items-per-page="itemsPerPage" v-model:options="options" @update:options="cargarData"
          :items-length="totalItems" density="compact" class="elevation-0" :items-per-page-options="itemsPorPagina">
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.inicio_fin`]="{ item }">
            {{
              item.inicio && item.fin
                ? moment(item.inicio).format('DD/MM/YYYY') + ' - ' + moment(item.fin).format('DD/MM/YYYY')
                : item.inicio
                  ? moment(item.inicio).format('DD/MM/YYYY') + ' - Sin fecha de fin'
                  : item.fin
                    ? 'Sin fecha de inicio - ' + moment(item.fin).format('DD/MM/YYYY')
                    : 'Fechas sin definir'
            }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="item.estado.color" size="small">{{ item.estado.descripcion }}</v-chip>
          </template>
          <template v-slot:[`item.ver`]="{ item }">
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" @click="abrirDialogoProgramarReincorporacion(item)" density="compact"
                    variant="text" icon="mdi-calendar-clock"></v-btn>
                </template>
                <span>Programar Reincorporación</span>
              </v-tooltip>
            </div>
          </template>
          <!-- Filtros en el encabezado de la tabla -->
          <template v-slot:thead>
            <tr>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaInicio" hide-details label="Fecha Inicio" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaFin" hide-details label="Fecha Fin" type="date" class="ma-2"
                      density="compact"></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-text-field v-model="params.queryPaciente" hide-details placeholder="Buscar Paciente" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryNumeroDocumento" hide-details placeholder="Buscar DNI" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-autocomplete v-model="params.queryDiagnosticos" :items="cie10List" item-title="descripcion"
                  item-value="id" multiple return-object chips density="compact" hide-details clearable closable-chips
                  v-model:search="searchCIE10" label="Buscar Diagnósticos" placeholder="Escriba para buscar..."
                  :loading="loadingDiagnosticos" @update:search="buscarDiagnosticos"></v-autocomplete>
              </td>
              <td>
                <v-select v-model="params.queryEstado" item-value="value" item-title="text" label="Buscar Estado"
                  multiple class="ma-2" hide-details :items="estados" density="compact"></v-select>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <ModalReincorporaciones :is-visible="dialogProgramarReincorporacion"
      :paciente-nombre="descansoSeleccionado?.paciente.full_name"
      :documento="descansoSeleccionado?.paciente.numero_documento" :descanso-id="descansoSeleccionado?.id"
      :programaciones="descansoSeleccionado?.programacionreincorporacion || []"
      @update:isVisible="dialogProgramarReincorporacion = $event" @reincorporacion-creada="cargarData" />



  </div>
</template>

<script>
import ModalReincorporaciones from "@/components/DescansoMedico/ModalReincorporaciones.vue";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ListadoDescansosMedicosViewSmcv",
  components: {
      ModalReincorporaciones
    },
  data() {
    return {
      itemsPorPagina: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
      ],
      itemsPerPage: 10,
      search: null,
      moment,
      options: {},
      // Objeto para filtros
      params: {
        queryFechaInicio: "",
        queryFechaFin: "",
        queryPaciente: "",
        queryNumeroDocumento: "",
        queryDiagnosticos: [],
        queryEstado: [],
      },
      dialogProgramarReincorporacion: false,
      fechaReincorporacion: null,
      horaReincorporacion: null,
      // Variables para búsqueda de pacientes
      pacientesAviso: [],
      pacienteSeleccionado: null,
      pacientes: [],
      loadingPacientes: false,
      // Variables para diagnósticos
      cie10List: [],
      loadingDiagnosticos: false,
      searchCIE10: "",
      // Opcionales: estados disponibles
      estados: [
        { value: "1", text: "SEGUIMIENTO" },
        { value: "2", text: "FINALIZADO" },
      ],
    };
  },
  computed: {
    ...mapState("descansosMedicos", ["total", "isLoading"]),
    ...mapGetters("descansosMedicos", ["descansos"]),
    totalItems() {
      return this.total;
    },
    headers() {
      return [
        { title: "Fecha", align: "center", sortable: false, key: "created_at" },
        { title: "Paciente", align: "center", sortable: false, key: "paciente.full_name" },
        { title: "Número de Documento", align: "center", sortable: false, key: "paciente.numero_documento" },
        { title: "Inicio-Fin", align: "center", sortable: false, key: "inicio_fin" },
        { title: "Estado", align: "center", sortable: false, key: "estado" },
        { title: "Acciones", align: "center", sortable: false, key: "ver" },
      ];
    },
  },
  methods: {
    ...mapActions("descansosMedicos", ["fetchDescansosSmcv", "searchPacientes"]),
    // Método para construir los filtros y llamar a la action fetchDescansosSmcv
    cargarData() {
      const params = {
        queryFechaInicio: this.params.queryFechaInicio || null,
        queryFechaFin: this.params.queryFechaFin || null,
        queryPaciente: this.params.queryPaciente || null,
        queryNumeroDocumento: this.params.queryNumeroDocumento || null,
        queryDiagnosticos:
          this.params.queryDiagnosticos && this.params.queryDiagnosticos.length > 0
            ? this.params.queryDiagnosticos.map((diag) => diag.id)
            : null,
        queryEstado:
          this.params.queryEstado && this.params.queryEstado.length > 0
            ? this.params.queryEstado.map((est) => est.value)
            : null,
        itemsPerPage: this.itemsPerPage,
      };
      this.fetchDescansosSmcv(params);
    },
    async cargarPacientes(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.pacientes = [];
        return;
      }
      this.loadingPacientes = true;
      try {
        this.pacientes = await this.searchPacientes({ query: searchValue });
      } finally {
        this.loadingPacientes = false;
      }
    },
    agregarPacienteAviso() {
      const paciente = this.pacientes.find((p) => p.id === this.pacienteSeleccionado);
      if (paciente) {
        this.pacientesAviso.push(paciente);
      }
      this.dialogAgregarPaciente = false;
      this.pacienteSeleccionado = null;
    },
    eliminarPacienteAviso(index) {
      this.pacientesAviso.splice(index, 1);
    },
    enviarAvisoSMCV() {
      console.log("Aviso enviado a SMCV con pacientes:", this.pacientesAviso);
      this.dialogAvisoSMCV = false;
    },
    abrirDialogoProgramarReincorporacion(item) {
      this.descansoSeleccionado = item;
      this.programacionesReincorporacion = item.programacionreincorporacion || [];
      this.dialogProgramarReincorporacion = true;
    },

    async guardarReprogramacion() {
      try {
        const payload = {
          id: this.descansoSeleccionado.id,
          fecha: this.fechaReincorporacion,
          hora: this.horaReincorporacion,
        };

        await this.$store.dispatch("descansosMedicos/guardarReincorporacion", payload);

        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Programación guardada correctamente");

        // Actualiza la lista localmente (opcional)
        this.programacionesReincorporacion.push({
          id: Date.now(), // ID temporal
          fecha_hora: `${this.fechaReincorporacion} ${this.horaReincorporacion}`,
          created_at: moment().format(),
        });

        // Limpieza
        this.fechaReincorporacion = null;
        this.horaReincorporacion = null;
      } catch (error) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", error.message);
      }
    },
    verDetalle(item) {
      this.$router.push({ path: `/detalledescansomedico/${item.id}` });
    },
    async buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      try {
        const data = await this.$store.dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue);
        this.cie10List = data.map((item) => ({
          id: item.id,
          descripcion: `${item.codigo} - ${item.descripcion}`,
        }));
      } catch (e) {
        console.error("Error al buscar diagnósticos:", e.message);
      } finally {
        this.loadingDiagnosticos = false;
      }
    },
  },
  created() {
    this.cargarData();
  },
  watch: {
    search(newVal) {
      this.cargarPacientes(newVal);
    },
    "params.queryFechaInicio": "cargarData",
    "params.queryFechaFin": "cargarData",
    "params.queryPaciente": "cargarData",
    "params.queryNumeroDocumento": "cargarData",
    "params.queryEstado": "cargarData",
    "params.queryDiagnosticos": {
      handler: "cargarData",
      deep: true,
    },
  },
};
</script>
