<template>
  <v-card>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-btn icon="mdi-plus" @click="openDialog" />
      <v-spacer></v-spacer>
      <h5 class="white--text">SEGUIMIENTOS</h5>
      <v-spacer></v-spacer>
      <v-btn icon="mdi-calendar-plus" @click="openDialogProgramar" />
    </v-toolbar>

    <v-card-text>
      <div class="timeline-container">
        <v-timeline side="end">
          <!-- Programaciones Futuras -->
          <template v-for="(programacion, index) in filteredFutureProgramaciones" :key="'prog-'+index">
            <v-timeline-item dot-color="red" size="small">
              <template v-slot:icon>
                <v-icon>mdi-calendar-clock</v-icon>
              </template>
              <div class="d-flex justify-space-between align-center">
                <div>
                  <div class="text-subtitle-2 font-weight-black">Seguimiento Programado</div>
                  <v-chip color="red" text-color="white" small>
                    {{ moment(programacion.fecha).format("DD-MMM-YYYY") }}
                  </v-chip>
                  <v-btn variant="text" icon="mdi-delete" color="red" size="small"
                    @click="borrarProgramacion(programacion)">
                  </v-btn>
                </div>
              </div>
            </v-timeline-item>
          </template>

          <!-- Bitácoras -->
          <template v-for="(bitacora, index) in orderedBitacoras" :key="bitacora.id + index">
            <v-timeline-item dot-color="green" size="small">
              <template v-slot:opposite>
                <strong class="me-4" v-if="bitacora.user">
                  {{ `${bitacora.user.nombres} ${bitacora.user.apellido_paterno} ${bitacora.user.apellido_materno}` }}
                </strong>
              </template>
              <template v-slot:icon>
                <v-avatar v-if="bitacora.user" :image="getUserImage(bitacora.user)"></v-avatar>
                <v-avatar v-else>
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </template>
              <div>
                <div class="text-subtitle-2 font-weight-black">
                  {{ moment(bitacora.created_at).format("DD-MMM-YYYY hh:mm A") }}
                    <v-btn variant="text" color="orange" icon="mdi-pencil" size="x-small" @click="editBitacora(bitacora)">
                    </v-btn>
                    <v-btn variant="text" icon="mdi-delete" color="red" size="x-small" @click="borrarBitacora(bitacora)">
                    </v-btn>
                </div>
                <p class="text-justify">{{ bitacora.descripcion }}</p>
                <p v-if="bitacora.observaciones_smcv" class="text-justify">
                  <strong>Observaciones SMCV:</strong> {{ bitacora.observaciones_smcv }}
                </p>
                <v-chip v-if="bitacora.estado_seguimiento" class="mt-2" color="blue" text-color="white">
                  {{ bitacora.estado_seguimiento }}
                </v-chip>

                <!-- Botones de acción -->

              </div>
            </v-timeline-item>
          </template>

          <div v-if="!orderedBitacoras.length" class="text-center">
            No hay registros en la bitácora.
          </div>
        </v-timeline>
      </div>
    </v-card-text>

    <!-- Diálogo Añadir/Editar Bitácora -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-form ref="form" validate-on="submit lazy" @submit.prevent="saveBitacora">
          <v-card-title>
            <span class="text-h6">{{ isEditing ? "Editar Bitácora" : "Añadir Bitácora" }}</span>
          </v-card-title>
          <v-card-text>
            <v-textarea v-model="newBitacora.descripcion" label="Descripción" density="compact"
              prepend-icon="mdi-note-text" :rules="rulesDescripcion" required></v-textarea>
            <v-textarea v-model="newBitacora.observaciones_smcv" label="Observaciones SMCV"
              hint="Este campo aparecerá en el reporte de Excel para SMCV." density="compact"
              prepend-icon="mdi-file-document-outline"></v-textarea>
            <v-select v-model="newBitacora.estado_seguimiento" :items="estadosSeguimiento" item-title="descripcion"
              item-value="id" label="Estado de Seguimiento" density="compact" prepend-icon="mdi-check-circle-outline"
              :rules="rulesEstado" required></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeDialog">Cancelar</v-btn>
            <v-btn color="blue darken-1" type="submit" text>Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Diálogo Programar Seguimiento -->
    <v-dialog v-model="dialogProgramarSeguimiento" persistent max-width="500">
      <v-card>
        <v-form ref="formProgramar" lazy-validation>
          <v-card-title>
            <span class="text-h6">Programar Seguimiento</span>
          </v-card-title>
          <v-card-text>
            <v-date-input v-model="programarFecha" label="Seleccione la fecha de seguimiento" locale="es-PE"
              prepend-icon="mdi-calendar" density="compact" :rules="rulesFecha"></v-date-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelarProgramarSeguimiento">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="confirmarProgramarSeguimiento">Aceptar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Diálogo de Confirmación para Eliminar -->
    <v-dialog v-model="confirmDeleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmar eliminación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas eliminar esta bitácora?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cancelarEliminacion">Cancelar</v-btn>
          <v-btn color="red" text @click="confirmarEliminacion">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loaders -->
    <v-dialog v-model="savingBitacora" persistent max-width="320">
      <v-list class="py-2" color="primary" elevation="12" rounded="lg">
        <v-list-item title="Guardando Bitácora...">
          <template v-slot:prepend>
            <v-icon color="primary" size="x-large">mdi-content-save</v-icon>
          </template>
          <template v-slot:append>
            <v-progress-circular color="primary" indeterminate size="16" width="2" />
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>

    <v-dialog v-model="savingProgramacion" persistent max-width="320">
      <v-list class="py-2" color="primary" elevation="12" rounded="lg">
        <v-list-item title="Programando Seguimiento...">
          <template v-slot:prepend>
            <v-icon color="primary" size="x-large">mdi-content-save</v-icon>
          </template>
          <template v-slot:append>
            <v-progress-circular color="primary" indeterminate size="16" width="2" />
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialogProgramacion" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmar eliminación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas eliminar esta programación de seguimiento?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="confirmDeleteDialogProgramacion = false">Cancelar</v-btn>
          <v-btn color="red" text @click="confirmarEliminacionProgramacion">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "BitacorasTimeline",
  data() {
    return {
      moment,
      dialog: false,
      dialogProgramarSeguimiento: false,
      confirmDeleteDialog: false,
      bitacoraToDelete: null,
      programarFecha: null,
      newBitacora: {
        descripcion: "",
        observaciones_smcv: "",
        estado_seguimiento: ""
      },
      bitacoraEditId: null,
      isEditing: false,
      savingBitacora: false,
      savingProgramacion: false,
      rulesDescripcion: [value => (value && value.trim() ? true : "La descripción es requerida")],
      rulesEstado: [value => (value ? true : "El estado de seguimiento es requerido")],
      rulesFecha: [value => !!value || "La fecha de seguimiento es requerida"],
      confirmDeleteDialogProgramacion: false, // <- nuevo
    };
  },
  computed: {
    ...mapState("descansosMedicos", ["descansoSeleccionado"]),
    ...mapGetters("descansosMedicos", ["estadosSeguimiento"]),
    orderedBitacoras() {
      const bitacoras = this.descansoSeleccionado?.bitacoras || [];
      return [...bitacoras].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    filteredFutureProgramaciones() {
      if (!this.descansoSeleccionado?.programacionbitacora) return [];
      const today = moment().startOf("day");
      return this.descansoSeleccionado.programacionbitacora
        .filter(p => moment(p.fecha).isSameOrAfter(today) && (!p.bitacoras || p.bitacoras.length === 0));
    }
  },
  methods: {
    ...mapActions("descansosMedicos", [
      "addBitacora",
      "fetchDescansoById",
      "fetchEstadosSeguimiento",
      "programarSeguimiento",
      "updateBitacora",
      "deleteBitacora"
    ]),
    openDialog() {
      this.resetForm();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.newBitacora = { descripcion: "", observaciones_smcv: "", estado_seguimiento: "" };
      this.bitacoraEditId = null;
      this.isEditing = false;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    async saveBitacora() {
      const isValid = await this.$refs.form.validate();
      if (!isValid.valid) return;
      this.savingBitacora = true;
      try {
        const payload = {
          ...this.newBitacora,
          descanso_id: this.descansoSeleccionado.id,
          programacion_bitacora_id: this.$route.params.programacionId || null,
        };
        if (this.isEditing && this.bitacoraEditId) {
          payload.id = this.bitacoraEditId;
          await this.updateBitacora(payload);
        } else {
          await this.addBitacora(payload);
        }
        await this.fetchDescansoById(this.descansoSeleccionado.id);
        this.closeDialog();
      } catch (error) {
        console.error("Error al guardar bitácora:", error);
      } finally {
        this.savingBitacora = false;
      }
    },
    editBitacora(bitacora) {
      console.log(bitacora)
      this.newBitacora = {
        descripcion: bitacora.descripcion,
        observaciones_smcv: bitacora.observaciones_smcv,
        estado_seguimiento: bitacora.estado_seguimiento_id
      };
      this.bitacoraEditId = bitacora.id;
      this.isEditing = true;
      this.dialog = true;
    },
    borrarBitacora(bitacora) {
      this.bitacoraToDelete = bitacora;
      this.confirmDeleteDialog = true;
    },
    borrarProgramacion(programacion) {
      this.bitacoraToDelete = programacion;
      this.confirmDeleteDialogProgramacion = true;
    },

    async confirmarEliminacionProgramacion() {
      try {
        await this.$store.dispatch("descansosMedicos/deleteProgramacionBitacora", this.bitacoraToDelete.id);
        await this.fetchDescansoById(this.descansoSeleccionado.id);
      } catch (error) {
        console.error("Error al eliminar programación:", error);
      } finally {
        this.confirmDeleteDialogProgramacion = false;
        this.bitacoraToDelete = null;
      }
    },
    async confirmarEliminacion() {
      try {
        await this.deleteBitacora(this.bitacoraToDelete.id);
        await this.fetchDescansoById(this.descansoSeleccionado.id);
      } catch (error) {
        console.error("Error al eliminar bitácora:", error);
      } finally {
        this.confirmDeleteDialog = false;
        this.bitacoraToDelete = null;
      }
    },
    cancelarEliminacion() {
      this.confirmDeleteDialog = false;
      this.bitacoraToDelete = null;
    },
    openDialogProgramar() {
      this.dialogProgramarSeguimiento = true;
    },
    async confirmarProgramarSeguimiento() {
      const isValid = await this.$refs.formProgramar.validate();
      if (!isValid.valid) return;
      this.savingProgramacion = true;
      try {
        await this.programarSeguimiento({
          descanso_id: this.descansoSeleccionado.id,
          fecha: moment(this.programarFecha).format("YYYY-MM-DD"),
          programacion_bitacora_id: this.$route.params.programacionId || null
        });
        await this.fetchDescansoById(this.descansoSeleccionado.id);
        this.dialogProgramarSeguimiento = false;
        this.programarFecha = null;
      } catch (error) {
        console.error("Error al programar seguimiento:", error);
      } finally {
        this.savingProgramacion = false;
      }
    },
    cancelarProgramarSeguimiento() {
      this.programarFecha = null;
      this.dialogProgramarSeguimiento = false;
    },
    getUserImage(user) {
      if (user.especialidad_usuario_id === 2 && user.cxp) {
        return `https://aplicaciones.cmp.org.pe/conoce_a_tu_medico/fotos/${user.cxp.replace(/^0+/, '')}.jpg`;
      } else if (user.especialidad_usuario_id === 3 && user.numero_documento) {
        return `https://www.cep.org.pe/fotos/${user.numero_documento.replace(/^0+/, '')}.jpg`;
      }
      return null;
    }
  },
  async mounted() {
    await this.fetchEstadosSeguimiento();
  }
};
</script>

<style scoped>
.timeline-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}
</style>
