import axios from "axios";

const descansosMedicos = {
  namespaced: true,
  state: {
    data: [], // Lista de descansos médicos
    total: 0, // Total de descansos
    descansoSeleccionado: null, // Descanso médico seleccionado
    isLoading: false, // Estado de carga
    reincorporacionSeleccionada: null, // Estado para rastrear la selección,
    subcategoriasCIE10: [],
    reincorporaciones: [], // Lista de reincorporaciones
    motivos: [], // Lista de motivos de descanso
    origenes: [], // Lista de orígenes del descanso
    estadosSeguimiento: [],
    distribucionDescansos: [],
    descansosAsignados: [],
    calificacionesReincorporacion: [],
  },
  getters: {
    descansos(state) {
      return state.data;
    },
    descansoById: (state) => (id) => {
      return state.data.find((descanso) => descanso.id === id);
    },
    isLoading(state) {
      return state.isLoading;
    },
    bitacoras(state) {
      return state.descansoSeleccionado?.bitacoras || [];
    },
    adjuntos(state) {
      return state.descansoSeleccionado?.adjuntos || [];
    },
    reincorporaciones(state) {
      return state.reincorporaciones;
    },
    motivos(state) {
      return state.motivos;
    },
    origenes(state) {
      return state.origenes;
    },
    estadosSeguimiento(state) {
      return state.estadosSeguimiento;
    },
    distribucionDescansos(state) {
      return state.distribucionDescansos; // Nuevo getter para obtener la distribución
    },
    descansosAsignados(state) {
      return state.descansosAsignados;
    },
    calificacionesReincorporacion(state) {
      return state.calificacionesReincorporacion;
    },
  },
  mutations: {
    SET_DESCANSOS(state, data) {
      state.data = data.data;
      state.total = data.total;
    },
    SET_DESCANSO_SELECCIONADO(state, data) {
      state.descansoSeleccionado = data;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    ADD_BITACORA(state, bitacora) {
      if (state.descansoSeleccionado) {
        if (!state.descansoSeleccionado.bitacoras) {
          state.descansoSeleccionado.bitacoras = [];
        }
        state.descansoSeleccionado.bitacoras.push(bitacora);
      }
    },
    SET_BITACORAS(state, bitacoras) {
      if (state.descansoSeleccionado) {
        state.descansoSeleccionado.bitacoras = bitacoras;
      }
    },
    SET_ADJUNTOS(state, adjuntos) {
      if (state.descansoSeleccionado) {
        state.descansoSeleccionado.adjuntos = adjuntos;
      }
    },
    ADD_ADJUNTO(state, adjunto) {
      if (state.descansoSeleccionado) {
        if (!state.descansoSeleccionado.adjuntos) {
          state.descansoSeleccionado.adjuntos = [];
        }
        state.descansoSeleccionado.adjuntos.push(adjunto);
      }
    },
    SET_REINCORPORACION(state, tipo) {
      state.reincorporacionSeleccionada = tipo;
    },
    SET_SUBCATEGORIAS_CIE10(state, data) {
      state.subcategoriasCIE10 = data;
    },
    SET_REINCORPORACIONES(state, data) {
      state.reincorporaciones = data;
    },
    SET_MOTIVOS(state, data) {
      state.motivos = data;
    },
    SET_ORIGENES(state, data) {
      state.origenes = data;
    },
    SET_ESTADOS_SEGUIMIENTO(state, estados) {
      state.estadosSeguimiento = estados;
    },
    SET_DISTRIBUCION_DESCANSOS(state, data) {
      state.distribucionDescansos = data; // Guardar los datos de la distribución
      state.totalDistribucion = data.length;
    },
    SET_DESCANSOS_ASIGNADOS(state, data) {
      state.descansosAsignados = data.data;
      state.total = data.total;
    },
    SET_PROGRAMACION_BITACORA(state, programacion) {
      if (state.descansoSeleccionado) {
        state.descansoSeleccionado.programacionbitacora = programacion;
      }
    },
    SET_NO_APTO(state, payload) {
      if (state.descansoSeleccionado) {
        state.descansoSeleccionado.noapto = payload;
      }
    },
    SET_CALIFICACIONES_REINCORPORACION(state, data) {
      state.calificacionesReincorporacion = data;
    },
  },
  actions: {
    async fetchDescansos({ commit }, params) {
      commit("SET_LOADING", true);
      const config = { params };
      try {
        const { data } = await axios.get("/api/descansomedico", config);
        commit("SET_DESCANSOS", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchDescansosSmcv({ commit }, params) {
      commit("SET_LOADING", true);
      const config = { params };
      try {
        const { data } = await axios.get("/api/descansomedicosmcv", config);
        commit("SET_DESCANSOS", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchDescansoById({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/descansomedico/${id}`);
        commit("SET_DESCANSO_SELECCIONADO", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async saveDescanso({ dispatch }, descanso) {
      try {
        if (descanso.id) {
          console.log("Actualizando descanso con ID:", descanso.id);
          await axios.put(`/api/descansomedico/${descanso.id}`, descanso);
        } else {
          console.log("Creando nuevo descanso médico");
          await axios.post("/api/descansomedico", descanso);
        }

        dispatch("fetchDescansos"); // Refrescar la lista después de guardar
      } catch (error) {
        throw new Error(
          error.response?.data.message || "Error al guardar el descanso médico."
        );
      }
    },
    async deleteDescanso({ dispatch }, id) {
      try {
        await axios.delete(`/api/descansomedico/${id}`);
        dispatch("fetchDescansos"); // Actualizar la lista después de eliminar
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async fetchBitacoras({ commit, state }) {
      try {
        const descansoId = state.descansoSeleccionado.id;

        // Obtener las bitácoras del backend
        const { data } = await axios.get(`/api/bitacora/${descansoId}`);
        console.log(data);

        // Actualizar las bitácoras en el estado
        commit("SET_BITACORAS", data);
      } catch (error) {
        throw new Error("Error al obtener bitácorass");
      }
    },
    async fetchEstadosSeguimiento({ commit }) {
      try {
        const { data } = await axios.get("/api/estadoseguimiento");
        commit("SET_ESTADOS_SEGUIMIENTO", data);
      } catch (error) {
        console.error("Error al obtener los estados de seguimiento:", error);
      }
    },
    async addBitacora({ commit, state }, bitacora) {
      try {
        const descansoId = state.descansoSeleccionado.id;
        // Se espera que bitacora incluya el campo "programacion_bitacora_id"
        const { data } = await axios.post("/api/bitacora", {
          descanso_medico_id: descansoId,
          descripcion: bitacora.descripcion,
          observaciones_smcv: bitacora.observaciones_smcv,
          estado_seguimiento: bitacora.estado_seguimiento,
          proximo_seguimiento: bitacora.proximo_seguimiento,
          programacion_bitacora_id: bitacora.programacion_bitacora_id || null,
        });
        commit("ADD_BITACORA", data);
      } catch (error) {
        console.error("Error al agregar bitácora:", error);
      }
    },
    async updateBitacora(_, bitacora) {
      try {
        await axios.put(`/api/bitacora/${bitacora.id}`, bitacora);
      } catch (error) {
        console.error("Error al actualizar bitácora:", error);
        throw error;
      }
    },

    async deleteBitacora(_, id) {
      console.log(id);
      try {
        await axios.delete(`/api/bitacora/${id}`);
      } catch (error) {
        console.error("Error al eliminar bitácora:", error);
        throw error;
      }
    },

    async addAdjunto({ commit }, formData) {
      try {
        // Subir el archivo al backend
        const { data } = await axios.post(
          "/api/adjuntodescansomedico",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Actualizar el estado con el nuevo adjunto
        commit("ADD_ADJUNTO", data);
      } catch (error) {
        throw new Error("Error al agregar adjunto");
      }
    },
    async deleteAdjunto({ commit, state }, adjuntoId) {
      try {
        await axios.delete(`/api/adjuntodescansomedico/${adjuntoId}`);
        const updatedAdjuntos = state.descansoSeleccionado.adjuntos.filter(
          (adjunto) => adjunto.id !== adjuntoId
        );
        commit("SET_ADJUNTOS", updatedAdjuntos);
      } catch (error) {
        throw new Error("Error al eliminar adjunto");
      }
    },
    async searchPacientes(_, { query }) {
      try {
        const response = await axios.get(`/api/pacientes/search`, {
          params: { query }, // Pasa el término de búsqueda como parámetro
        });
        return response.data; // Devuelve la lista de pacientes
      } catch (e) {
        console.error(
          "Error al buscar pacientes:",
          e.response?.data?.error || e.message
        );
        throw new Error(e.response?.data?.error || "Error al buscar pacientes");
      }
    },
    async fetchSubcategoriasCIE10({ commit }, searchQuery) {
      try {
        // No hace la petición si la búsqueda tiene menos de 3 caracteres
        if (!searchQuery || searchQuery.length < 3) {
          commit("SET_SUBCATEGORIAS_CIE10", []);
          return [];
        }

        const response = await axios.get("/api/subcategoriacie/search", {
          params: { query: searchQuery },
        });

        commit("SET_SUBCATEGORIAS_CIE10", response.data);
        return response.data;
      } catch (error) {
        console.error(
          "Error al obtener subcategorías CIE-10:",
          error.response?.data || error.message
        );
        return [];
      }
    },
    async exportarExcel(_, params) {
      try {
        const response = await axios.post(
          "/api/descansomedico/export",
          params,
          {
            responseType: "blob", // Importante para descargar archivos
          }
        );

        // Crear una URL para el archivo y descargarlo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Descansos_Medicos.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error al exportar el Excel:", error);
        throw new Error("No se pudo descargar el archivo");
      }
    },
    async fetchReincorporaciones({ commit }) {
      try {
        const { data } = await axios.get("/api/reincorporacion");
        commit("SET_REINCORPORACIONES", data);
      } catch (error) {
        console.error("Error al obtener reincorporaciones:", error);
      }
    },
    async fetchMotivos({ commit }) {
      try {
        const { data } = await axios.get("/api/motivodescansomedico");
        commit("SET_MOTIVOS", data);
      } catch (error) {
        console.error("Error al obtener motivos de descanso:", error);
      }
    },
    async fetchOrigenes({ commit }) {
      try {
        const { data } = await axios.get("/api/origendescansomedico");
        commit("SET_ORIGENES", data);
      } catch (error) {
        console.error("Error al obtener orígenes del descanso:", error);
      }
    },
    async guardarAmpliacion({ dispatch }, payload) {
      try {
        const response = await axios.post(
          "/api/descansomedico/ampliacion",
          payload
        );
        // Se asume que payload.descansomedico_id contiene el ID del descanso médico a actualizar
        await dispatch("fetchDescansoById", payload.descansomedico_id);
        return response.data;
      } catch (error) {
        console.error(
          "Error al guardar ampliación de descanso médico:",
          error.response?.data || error.message
        );
        throw new Error(
          error.response?.data?.message || "Error al guardar la ampliación."
        );
      }
    },
    async eliminarAmpliacion(_, id) {
      try {
        await axios.delete(`/api/ampliacion/${id}`);
      } catch (error) {
        console.error("Error al eliminar la ampliación:", error);
        throw new Error("No se pudo eliminar la ampliación");
      }
    },
    async guardarSaludOcupacional({ dispatch, state }, { id, datosPaciente }) {
      try {
        await axios.post(
          `/api/pacientes/${id}/salud-ocupacional`,
          datosPaciente
        );

        console.log("Datos guardados correctamente");

        // Buscar el descanso médico asociado al paciente en el estado actual
        const descansoId = state.descansoSeleccionado?.id;

        if (descansoId) {
          // Llamar a fetchDescansoById para actualizar el estado con los datos más recientes del descanso médico
          await dispatch("fetchDescansoById", descansoId);
        } else {
          console.warn(
            "No se encontró el ID del descanso médico para actualizar."
          );
        }
      } catch (error) {
        console.error(
          "Error al guardar Salud Ocupacional:",
          error.response?.data || error.message
        );
        throw new Error("Error al guardar datos de Salud Ocupacional");
      }
    },
    async fetchDistribucion({ commit }, fecha) {
      commit("SET_LOADING", true);
      try {
        const config = { params: {} };
        if (fecha) {
          config.params.fecha = fecha;
        }
        const { data } = await axios.get("/api/distribucion-descansos", config);
        commit("SET_DISTRIBUCION_DESCANSOS", data);
      } catch (error) {
        console.error("Error al obtener la distribución de descansos:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchdescansosasignados({ commit }, { userId, params }) {
      commit("SET_LOADING", true);
      try {
        const data = await axios.get(
          `/api/descansomedico/asignados/${userId}`,
          {
            params,
          }
        );
        commit("SET_DESCANSOS_ASIGNADOS", data);
      } catch (error) {
        throw new Error(error.response?.data.message);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async asignarDescansos({ dispatch }, payload) {
      try {
        await axios.post("/api/asignar-descansos", payload);
        await dispatch("fetchDistribucion"); // Recargar la distribución después de asignar
        console.log("Asignaciones guardadas correctamente.");
      } catch (error) {
        console.error("Error al asignar descansos médicos:", error);
        throw new Error(
          error.response?.data?.message || "Error al asignar descansos médicos."
        );
      }
    },
    async guardarReincorporacion(_, payload) {
      try {
        // Realiza la petición al backend utilizando la ruta del recurso programacionReincoporacion
        const response = await axios.post(`/api/programacionReincoporacion`, {
          descanso_medico_id: payload.id, // Se asume que payload.id es el ID del descanso médico
          fecha: payload.fecha,
          hora: payload.hora,
        });
        return response.data;
      } catch (error) {
        throw new Error(
          error.response?.data.message || "Error al guardar la reprogramación"
        );
      }
    },
    async fetchBitacorasEvento({ commit }, descansoId) {
      try {
        // Hacer la petición al backend enviando el ID del descanso médico
        const response = await axios.get(
          `/api/descansomedico/${descansoId}/bitacoras`
        );

        console.log("Bitácoras y programación obtenidas:", response.data);

        // Almacenar las bitácoras en el estado Vuex
        commit("SET_BITACORAS", response.data.bitacoras);

        // Si quieres guardar la programación de bitácoras en otro estado
        commit("SET_PROGRAMACION_BITACORA", response.data.programacionbitacora);
      } catch (error) {
        console.error(
          "Error al obtener las bitácoras y programación del descanso médico:",
          error.response?.data || error.message
        );
        throw new Error("Error al obtener bitácoras y programación");
      }
    },
    async programarSeguimiento({ commit }, payload) {
      try {
        // payload debe contener: { descanso_id, fecha }
        const response = await axios.post(
          "/api/programacionseguimiento",
          payload
        );
        // Si el backend devuelve la programación actualizada, se actualiza el estado
        if (response.data.programacionbitacora) {
          commit(
            "SET_PROGRAMACIONBITACORA",
            response.data.programacionbitacora
          );
        }
        return response.data;
      } catch (error) {
        console.error("Error en la acción programarSeguimiento:", error);
        throw error;
      }
    },
    async deleteProgramacionBitacora(_, id) {
      try {
        await axios.delete(`/api/programacionseguimiento/${id}`);
      } catch (error) {
        console.error("Error al eliminar programación:", error);
        throw new Error("Error al eliminar la programación");
      }
    },
    async fetchDescansosPresenciales({ commit }, params) {
      commit("SET_LOADING", true);
      const config = { params };
      try {
        // Llamada al endpoint específico para reincorporaciones presenciales
        const { data } = await axios.get("/api/descansomedicosmcv", config);
        commit("SET_DESCANSOS", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async marcarTerminado(_, payload) {
      try {
        // Se espera que payload incluya: { id: <pivotId>, terminado: <boolean> }
        const { data } = await axios.patch(
          `/api/bitacora_programacion_bitacora/${payload.id}`,
          {
            terminado: payload.terminado, // o puedes fijarlo como true si siempre se marca como terminado
          }
        );

        return data;
      } catch (error) {
        console.error("Error al marcar terminado:", error);
        throw new Error(
          error.response?.data?.message || "Error al marcar terminado"
        );
      }
    },
    actualizarNoApto({ commit }, payload) {
      // payload puede ser null o un objeto, por ejemplo: { descripcion: "motivo..." }
      commit("SET_NO_APTO", payload);
    },
    async fetchCalificacionesReincorporacion({ commit }) {
      try {
        const { data } = await axios.get("/api/calificacionreincorporacion");
        commit("SET_CALIFICACIONES_REINCORPORACION", data);
      } catch (error) {
        console.error(
          "Error al obtener calificaciones reincorporacion:",
          error
        );
      }
    },
    async actualizarAptitudComentario(_, { id, aptitud, comentario }) {
      try {
        const { data } = await axios.post("/api/reincorporacion/calificar", {
          id,
          aptitud,
          comentario,
        });
        return data;
      } catch (error) {
        console.error("Error al actualizar aptitud y comentario:", error);
        throw new Error(
          error.response?.data?.message ||
            "Error al actualizar aptitud y comentario"
        );
      }
    },
    async fetchReincorporacionesProgramadas({ commit }, params) {
      commit("SET_LOADING", true);
      try {
        const { data } = await axios.get(
          "/api/descansomedico/reincorporaciones/programadas",
          {
            params,
          }
        );
        commit("SET_DESCANSOS", data);
      } catch (error) {
        console.error("Error al obtener reincorporaciones programadas:", error);
        throw new Error(
          error.response?.data?.message || "Error al cargar reincorporaciones"
        );
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};

export default descansosMedicos;
