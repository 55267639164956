<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title class="d-flex justify-space-between">
        LISTADO DE DESCANSOS MÉDICOS
        <v-spacer></v-spacer>
        <!-- Botón para Reporte SMCV: al hacer clic se abre el diálogo para seleccionar la fecha -->
        <v-btn size="small" variant="tonal" color="green" append-icon="mdi-microsoft-excel"
          @click="abrirDialogReporteSMCV">
          REPORTE SMCV
        </v-btn>
        <v-btn prepend-icon="mdi-plus" size="small" variant="tonal" class="ms-2" color="orange-darken-1"
          @click="abrirDialogoAgregar">
          Nuevo Descanso Médico
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table-server v-if="descansos" :headers="headers" :items="descansos"
          :loading="isLoading" v-model:items-per-page="itemsPerPage" v-model:options="options"
          @update:options="cargarData()" :items-length="totalItems" density="compact" class="elevation-0"
          :items-per-page-options="itemsPorPagina">
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.inicio_fin`]="{ item }">
            {{
              item.inicio
                ? moment(item.inicio).format('DD/MM/YYYY') + ' - ' + obtenerFechaFinMasReciente(item)
                : 'Fechas sin definir'
            }}
          </template>
          <template v-slot:[`item.diagnosticos`]="{ item }">
            <div class="d-flex">
              <v-tooltip v-for="diagnostico in obtenerTodosLosDiagnosticos(item)" :key="diagnostico.id" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" class="ma-1" color="blue" size="small">
                    {{ diagnostico.codigo }}
                  </v-chip>
                </template>
                <span>{{ diagnostico.descripcion }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="item.estado.color" size="small">{{ item.estado.descripcion }}</v-chip>
          </template>
          <template v-slot:[`item.ver`]="{ item }">
            <div class="d-flex">
              <v-btn @click="verDetalle(item)" density="compact" variant="text" icon="mdi-magnify"></v-btn>
              <v-btn
                v-if="$store.getters['currentUser'].rol.permiso.some(permiso => permiso.descripcion === 'delete_descanso')"
                @click="showConfirmDialog(item)" density="compact" variant="text" icon="mdi-delete"></v-btn>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaInicio" hide-details label="Fecha Inicio" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaFin" hide-details label="Fecha Fin" type="date" class="ma-2"
                      density="compact"></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-text-field v-model="params.queryPaciente" hide-details placeholder="Buscar Paciente" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryNumeroDocumento" hide-details placeholder="Buscar DNI" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-autocomplete v-model="params.queryDiagnosticos" :items="cie10List" item-title="descripcion"
                  item-value="id" multiple return-object chips density="compact" hide-details clearable closable-chips
                  v-model:search="searchCIE10" label="Buscar Diagnósticos" placeholder="Escriba para buscar..."
                  :loading="loadingDiagnosticos" @update:search="buscarDiagnosticos"></v-autocomplete>
              </td>
              <td>
                <v-select v-model="params.queryEstado" item-value="value" item-title="text" label="Buscar Estado"
                  multiple class="ma-2" hide-details :items="estados" density="compact"></v-select>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <!-- Diálogo para agregar descanso médico -->
    <v-dialog v-model="dialogAgregar" max-width="600px">
      <v-card>
        <v-card-title>Registrar Descanso Médico</v-card-title>
        <v-card-text>
          <v-autocomplete v-model="pacienteSeleccionado" :items="pacientes" v-model:search="search"
            item-title="full_name" item-value="id" label="Buscar Paciente" placeholder="Escriba para buscar..."
            :loading="loadingPacientes" hide-no-data hide-details clearable dense
            prepend-icon="mdi-account"></v-autocomplete>
          <v-text-field v-model="fechaInicio" label="Fecha de Inicio" type="date" class="mt-3" hide-details
            prepend-icon="mdi-calendar-start"></v-text-field>
          <v-text-field v-model="fechaFin" label="Fecha de Fin" type="date" class="mt-3" hide-details
            :disabled="sinFechaFin" prepend-icon="mdi-calendar-end"></v-text-field>
          <v-switch v-model="sinFechaFin" label="Sin fecha de fin" class="mt-3" hide-details
            prepend-icon="mdi-calendar-remove"></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>     
          <v-btn text @click="dialogAgregar = false">Cancelar</v-btn>
          <v-btn color="blue" :disabled="!pacienteSeleccionado || !fechaInicio" @click="guardarDescanso">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo para Programar Reincorporación -->
    <v-dialog v-model="dialogProgramarReincorporacion" max-width="500px">
      <v-card>
        <v-card-title>Programar Reincorporación</v-card-title>
        <v-card-text>
          <v-text-field v-model="fechaReincorporacion" label="Fecha de Reincorporación" type="date"
            hide-details></v-text-field>
          <v-text-field v-model="horaReincorporacion" label="Hora de Reincorporación" type="time"
            hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" @click="guardarReprogramacion">Guardar</v-btn>
          <v-btn text @click="dialogProgramarReincorporacion = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo para Reporte SMCV -->
    <v-dialog v-model="dialogReporteSMCV" max-width="400px">
      <v-card>
        <v-card-title>Reporte SMCV</v-card-title>
        <v-card-text>
          <v-text-field v-model="reporteFecha" label="Fecha del Reporte" type="date" hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" :disabled="!reporteFecha" @click="generarReporteSMCV">
            Generar Reporte
          </v-btn>
          <v-btn text @click="dialogReporteSMCV = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog :isVisible="showDeleteDialog" title="Confirmación"
      message="¿Está seguro de borrar este descanso médico?" @update:isVisible="showDeleteDialog = $event"
      @confirm="confirmarEliminar" @cancel="cancelarEliminar" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ListadoDescansosMedicosView",
  data() {
    return {
      searchCIE10: "",
      cie10List: [],
      loadingDiagnosticos: false,
      params: {
        queryDiagnosticos: [],
      },
      itemsPorPagina: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      itemsPerPage: 10,
      search: null,
      moment,
      options: {},
      dialogAgregar: false,
      dialogReporteSMCV: false,
      dialogProgramarReincorporacion: false,
      reporteFecha: null,  // Variable para la fecha del reporte SMCV
      fechaReincorporacion: null,
      horaReincorporacion: null,
      pacienteSeleccionado: null,
      pacientes: [],
      loadingPacientes: false,
      showDeleteDialog: false,
      itemToDelete: null,
      estados: [
        { value: "1", text: "SEGUIMIENTO" },
        { value: "2", text: "FINALIZADO" },
      ],
      fechaInicio: null,
      fechaFin: null,
      sinFechaFin: false,
    };
  },
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState("descansosMedicos", ["data", "total", "isLoading"]),
    ...mapGetters("descansosMedicos", ["descansos"]),
    totalItems() {
      return this.total; // Se utiliza el total de registros devuelto por el backend
    },
    headers() {
      return [
        { title: "Fecha", align: "center", sortable: false, key: "inicio_fin" },
        { title: "Paciente", align: "center", sortable: false, key: "paciente.full_name" },
        { title: "Número de Documento", align: "center", sortable: false, key: "paciente.numero_documento" },
        { title: "Diagnósticos", align: "center", sortable: false, key: "diagnosticos", width: "300px" },
        { title: "Estado", align: "center", sortable: false, key: "estado" },
        { title: "Resumen", align: "center", sortable: false, key: "resumen" },
        { title: "Acciones", align: "center", sortable: false, key: "ver" },
      ];
    },
  },
  methods: {
    ...mapActions("descansosMedicos", [
      "fetchDescansos",
      "deleteDescanso",
      "saveDescanso",
      "exportarExcel",
    ]),
    obtenerFechaFinMasReciente(item) {
      if (!item) return "Sin fecha de fin";

      let fechaFin = item.fin ? moment(item.fin) : null;

      if (item.ampliacion && Array.isArray(item.ampliacion)) {
        item.ampliacion.forEach((ampliacion) => {
          if (ampliacion.fecha_fin) {
            let fechaAmpliacion = moment(ampliacion.fecha_fin);
            if (!fechaFin || fechaAmpliacion.isAfter(fechaFin)) {
              fechaFin = fechaAmpliacion;
            }
          }
        });
      }

      return fechaFin ? fechaFin.format("DD/MM/YYYY") : "Sin fecha de fin";
    },
    async cargarData() {
      const params = {
        ...this.params,
        queryDiagnosticos: this.params.queryDiagnosticos.map(d => d.id), // Enviar solo los IDs al backend
        ...this.options,
      };
      try {
        await this.fetchDescansos(params);
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    },
    async buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      try {
        const data = await this.$store.dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue);
        this.cie10List = data.map((item) => ({
          id: item.id,
          descripcion: `${item.codigo} - ${item.descripcion}`,
        }));
      } catch (e) {
        console.error("Error al buscar diagnósticos:", e.message);
      } finally {
        this.loadingDiagnosticos = false;
      }
    },
    async cargarPacientes(searchValue) {
      if (searchValue.length < 3) {
        this.pacientes = [];
        return;
      }
      this.loadingPacientes = true;
      try {
        this.pacientes = await this.$store.dispatch("descansosMedicos/searchPacientes", { query: searchValue });
      } finally {
        this.loadingPacientes = false;
      }
    },
    abrirDialogoAgregar() {
      this.dialogAgregar = true;
      this.pacienteSeleccionado = null;
    },
    async guardarDescanso() {
      if (!this.pacienteSeleccionado || !this.fechaInicio) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", "Seleccione un paciente y una fecha de inicio");
        return;
      }
      try {
        await this.saveDescanso({
          paciente_id: this.pacienteSeleccionado,
          inicio: this.fechaInicio,
          fin: this.sinFechaFin ? null : this.fechaFin,
        });
        this.dialogAgregar = false;
        this.pacienteSeleccionado = null;
        this.fechaInicio = null;
        this.fechaFin = null;
        this.sinFechaFin = false;
        this.cargarData();
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Descanso médico agregado correctamente");
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    },
    verDetalle(item) {
      this.$router.push({ path: `/detalledescansomedicoadmin/${item.id}` });
    },
    showConfirmDialog(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    obtenerTodosLosDiagnosticos(item) {
      let diagnosticos = [...item.diagnosticos];
      if (item.ampliacion && Array.isArray(item.ampliacion)) {
        item.ampliacion.forEach((ampl) => {
          if (ampl.diagnosticos && Array.isArray(ampl.diagnosticos)) {
            diagnosticos = [...diagnosticos, ...ampl.diagnosticos];
          }
        });
      }
      const diagnosticosUnicos = [];
      const ids = new Set();
      diagnosticos.forEach((diag) => {
        if (!ids.has(diag.id)) {
          ids.add(diag.id);
          diagnosticosUnicos.push(diag);
        }
      });
      return diagnosticosUnicos;
    },
    async confirmarEliminar() {
      if (this.itemToDelete) {
        try {
          await this.deleteDescanso(this.itemToDelete.id);
          this.showDeleteDialog = false;
          this.itemToDelete = null;
          this.cargarData();
        } catch (e) {
          this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
        }
      }
    },
    cancelarEliminar() {
      this.showDeleteDialog = false;
      this.itemToDelete = null;
    },
    abrirDialogReporteSMCV() {
      this.dialogReporteSMCV = true;
    },
    generarReporteSMCV() {
      if (!this.reporteFecha) return;
      const params = {
        reporteFecha: this.reporteFecha,
      };
      this.exportarExcel(params);
      this.dialogReporteSMCV = false;
      this.reporteFecha = null;
    },
    guardarReprogramacion() {
      console.log("Guardar reprogramación para el descanso", this.descansoSeleccionado.id);
      console.log("Fecha:", this.fechaReincorporacion, "Hora:", this.horaReincorporacion);
      this.dialogProgramarReincorporacion = false;
      this.fechaReincorporacion = null;
      this.horaReincorporacion = null;
    }
  },
  created() {
    this.cargarData();
  },
  watch: {
    search(newVal) {
      this.cargarPacientes(newVal);
    },
    "params.queryFechaInicio": "cargarData",
    "params.queryFechaFin": "cargarData",
    "params.queryPaciente": "cargarData",
    "params.queryEstado": "cargarData",
    "params.queryNumeroDocumento": "cargarData",
    "params.queryDiagnosticos": {
      handler: "cargarData",
      deep: true,
    },
  },
};
</script>
