<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div>LISTADO DE USUARIOS</div>
        <v-spacer />
        <v-btn color="indigo-darken-4" @click="abrirDialogoAgregar">Registrar Usuario</v-btn>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="search"
          label="Buscar por nombre o documento"
          append-icon="mdi-magnify"
          variant="filled"
          clearable
          class="mb-3"
          @input="buscarUsuarios"
        />

        <v-data-table-server
          :headers="headers"
          :items="usuarios"
          :loading="isLoading"
          :items-per-page="itemsPerPage"
          :items-length="total"
          v-model:options="options"
          @update:options="cargarUsuarios"
          density="compact"
          class="elevation-0"
        >
          <template v-slot:[`item.nombre_completo`]="{ item }">
            {{ item.nombres }} {{ item.apellido_paterno }} {{ item.apellido_materno }}
          </template>

          <template v-slot:[`item.email`]="{ item }">
            {{ item.email }}
          </template>

          <template v-slot:[`item.rol_id`]="{ item }">
            {{ obtenerNombreRol(item.rol_id) }}
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="editarUsuario(item)" variant="text">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="confirmarEliminar(item)" variant="text">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <!-- Diálogo Agregar / Editar Usuario -->
    <v-dialog v-model="dialogAgregar" max-width="700px">
      <v-card>
        <v-card-title>{{ editando ? 'Editar Usuario' : 'Registrar Nuevo Usuario' }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.nombres" label="Nombres" variant="filled" required />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.apellido_paterno" label="Apellido Paterno" variant="filled" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.apellido_materno" label="Apellido Materno" variant="filled" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.cxp" label="CXP" variant="filled" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.numero_documento" label="Número de Documento" variant="filled" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="nuevoUsuario.especialidad_usuario_id"
                  :items="especialidades"
                  item-title="text"
                  item-value="value"
                  label="Especialidad"
                  variant="filled"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="nuevoUsuario.rol_id"
                  :items="roles"
                  item-title="text"
                  item-value="value"
                  label="Rol"
                  variant="filled"
                  clearable
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nuevoUsuario.email" label="Correo" variant="filled" required />
              </v-col>

              <!-- Contraseña -->
              <v-col cols="12" sm="6" v-if="!editando || editandoPassword">
                <v-text-field
                  v-model="nuevoUsuario.password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Contraseña"
                  variant="filled"
                  :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  append-icon="mdi-lock-reset"
                  @click:append-inner="togglePassword"
                  @click:append="generarPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" v-if="!editando || editandoPassword">
                <v-text-field
                  v-model="confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirmar Contraseña"
                  variant="filled"
                  :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append-inner="togglePassword"
                />
              </v-col>
              <v-col cols="12" v-if="editando && !editandoPassword">
                <v-btn variant="outlined" @click="editandoPassword = true">Cambiar Contraseña</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />          
          <v-btn text @click="dialogAgregar = false">Cancelar</v-btn>
          <v-btn color="blue" @click="guardarUsuario">{{ editando ? 'Actualizar' : 'Guardar' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :isVisible="showDeleteDialog"
      title="Eliminar Usuario"
      message="¿Está seguro que desea eliminar este usuario?"
      @update:isVisible="showDeleteDialog = $event"
      @confirm="eliminarUsuario"
      @cancel="cancelarEliminar"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import debounce from "lodash/debounce";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ListadoUsuariosView",
  components: { ConfirmDialog },
  data() {
    return {
      dialogAgregar: false,
      editando: false,
      editandoPassword: false,
      showPassword: false,
      confirmPassword: "",
      showDeleteDialog: false,
      usuarioAEliminar: null,
      itemsPerPage: 10,
      options: {},
      search: "",
      nuevoUsuario: this.getEmptyUsuario(),
    };
  },
  computed: {
    ...mapState("usuarios", ["total"]),
    ...mapGetters("usuarios", ["usuarios", "roles", "especialidades"]),
    headers() {
      return [
        { title: "Nombre Completo", key: "nombre_completo" },
        { title: "Correo", key: "email" },
        { title: "Rol", key: "rol_id" },
        { title: "Acciones", key: "acciones", sortable: false, align: "center" },
      ];
    },
    isLoading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    ...mapActions("usuarios", [
      "fetchUsuarios",
      "fetchRoles",
      "fetchEspecialidades",
      "saveUsuario",
      "updateUsuario",
      "deleteUsuario",
    ]),

    async cargarUsuarios() {
      const { page, itemsPerPage } = this.options;
      await this.fetchUsuarios({ page, per_page: itemsPerPage, search: this.search });
    },

    buscarUsuarios: debounce(function () {
      this.cargarUsuarios();
    }, 500),

    abrirDialogoAgregar() {
      this.dialogAgregar = true;
      this.editando = false;
      this.editandoPassword = true;
      this.nuevoUsuario = this.getEmptyUsuario();
      this.confirmPassword = "";
    },

    editarUsuario(usuario) {
      this.dialogAgregar = true;
      this.editando = true;
      this.editandoPassword = false;
      this.nuevoUsuario = { ...usuario, password: "" };
      this.confirmPassword = "";
    },

    async guardarUsuario() {
      if ((!this.editando || this.editandoPassword) && this.nuevoUsuario.password !== this.confirmPassword) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", "Las contraseñas no coinciden");
        return;
      }

      const payload = { ...this.nuevoUsuario };
      if (this.editando && !this.editandoPassword) {
        delete payload.password;
      }

      try {
        if (this.editando) {
          await this.updateUsuario(payload);
          this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Usuario actualizado");
        } else {
          await this.saveUsuario(payload);
          this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Usuario creado");
        }

        this.dialogAgregar = false;
        this.cargarUsuarios();
      } catch {
        this.$store.commit("SHOW_ERROR_SNACKBAR", "Ocurrió un error al guardar");
      }
    },

    confirmarEliminar(usuario) {
      this.usuarioAEliminar = usuario;
      this.showDeleteDialog = true;
    },

    async eliminarUsuario() {
      try {
        await this.deleteUsuario(this.usuarioAEliminar.id);
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Usuario eliminado");
        this.cargarUsuarios();
      } catch {
        this.$store.commit("SHOW_ERROR_SNACKBAR", "Error al eliminar usuario");
      } finally {
        this.showDeleteDialog = false;
        this.usuarioAEliminar = null;
      }
    },

    cancelarEliminar() {
      this.showDeleteDialog = false;
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    generarPassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
      const length = 12;
      let password = "";
      for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      this.nuevoUsuario.password = password;
      this.confirmPassword = password;
      this.showPassword = true;
      this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Contraseña generada");
    },

    obtenerNombreRol(id) {
      const rol = this.roles.find((r) => r.value === id);
      return rol ? rol.text : "Sin rol";
    },

    getEmptyUsuario() {
      return {
        nombres: "",
        apellido_paterno: "",
        apellido_materno: "",
        cxp: "",
        numero_documento: "",
        especialidad_usuario_id: null,
        rol_id: null,
        email: "",
        password: "",
      };
    },
  },
  created() {
    this.cargarUsuarios();
    this.fetchRoles();
    this.fetchEspecialidades();
  },
};
</script>
