<template>
  <v-card outlined class="mt-0">
    <v-card-title class="d-flex justify-space-between">
      LISTADO DE DESCANSOS MÉDICOS
      <v-spacer></v-spacer>
      <!-- Puedes agregar botones adicionales si lo requieres -->
    </v-card-title>
    <v-card-text>
      <!-- Usamos v-data-table-virtual para virtualizar la tabla -->
      <v-data-table-virtual
        :headers="headers"
        :items="descansosAsignados"
        height="400"
        fixed-header
      >
        <!-- Encabezado personalizado con filtros -->
        <template #thead>
          <tr>
            <!-- Columna de Fecha (sin filtro) -->
            <td></td>
            <!-- Filtro para Paciente -->
            <td>
              <v-text-field
                v-model="params.queryPaciente"
                hide-details
                placeholder="Buscar Paciente"
                type="text"
                class="ma-2"
                density="compact"
              ></v-text-field>
            </td>
            <!-- Filtro para Número de Documento -->
            <td>
              <v-text-field
                v-model="params.queryNumeroDocumento"
                hide-details
                placeholder="Buscar DNI"
                type="text"
                class="ma-2"
                density="compact"
              ></v-text-field>
            </td>
            <!-- Filtro para Diagnósticos -->
            <td>
              <v-autocomplete
                v-model="params.queryDiagnosticos"
                :items="cie10List"
                item-title="descripcion"
                item-value="id"
                multiple
                return-object
                chips
                density="compact"
                hide-details
                clearable
                closable-chips
                v-model:search="searchCIE10"
                label="Buscar Diagnósticos"
                placeholder="Escriba para buscar..."
                :loading="loadingDiagnosticos"
                @update:search="buscarDiagnosticos"
              ></v-autocomplete>
            </td>
            <!-- Columna para Acciones (sin filtro) -->
            <td></td>
          </tr>
        </template>

        <!-- Renderizado de celdas -->
        <template v-slot:[`item.fecha`]="{ item }">      
          {{ moment(item.created_at).format('DD/MM/YYYY') }}
        </template>

        <template v-slot:[`item.inicio_fin`]="{ item }">
          {{
            item.descansomedico.inicio
              ? moment(item.descansomedico.inicio).format('DD/MM/YYYY') +
                ' - ' +
                obtenerFechaFinMasReciente(item.descansomedico)
              : 'Fechas sin definir'
          }}
        </template>

        <template v-slot:[`item.diagnostico`]="{ item }">
          <div class="d-flex">
            <v-tooltip
              v-for="diagnostico in obtenerTodosLosDiagnosticos(item.descansomedico)"
              :key="diagnostico.id"
              location="top"
            >
              <template #activator="{ props }">
                <v-chip v-bind="props" class="ma-1" color="blue" size="small">
                  {{ diagnostico.codigo }}
                </v-chip>
              </template>
              <span>{{ diagnostico.descripcion }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.realizado`]="{ item }">
          <v-chip :color="item.bitacoras && item.bitacoras.length > 0 ? 'green' : 'red'" small>
            {{ item.bitacoras && item.bitacoras.length > 0 ? 'Realizado' : 'Pendiente' }}
          </v-chip>
        </template>

        <template v-slot:[`item.terminado`]="{ item }">
          <v-checkbox
            :model-value="item.terminado == 1"
            color="green"
            hide-details
            density="compact"
            @click.stop="toggleTerminado(item)"
          />
        </template>

        <template v-slot:[`item.estado`]="{ item }">
          <v-chip :color="item.descansomedico.estado.color" size="small">
            {{ item.descansomedico.estado.descripcion }}
          </v-chip>
        </template>

        <template v-slot:[`item.ver`]="{ item }">
          <div class="d-flex">
            <v-btn
              @click="verDetalle(item)"
              density="compact"
              variant="text"
              icon="mdi-magnify"
            ></v-btn>
            <v-btn
              v-if="$store.getters['currentUser'].rol.permiso.some(permiso => permiso.descripcion === 'delete_descanso')"
              @click="showConfirmDialog(item)"
              density="compact"
              variant="text"
              icon="mdi-delete"
            ></v-btn>
          </div>
        </template>
      </v-data-table-virtual>
    </v-card-text>
  </v-card>

  <!-- Se incluye el ConfirmDialog para evitar errores de componente no usado -->
  <ConfirmDialog
    :isVisible="showDeleteDialog"
    title="Confirmación"
    message="¿Está seguro de borrar este descanso médico?"
    @update:isVisible="showDeleteDialog = $event"
    @confirm="confirmarEliminar"
    @cancel="cancelarEliminar"
  />
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions } from "vuex"
import ConfirmDialog from "@/components/ConfirmDialog.vue"

export default {
  name: "ListadoDescansosMedicosView",
  components: { ConfirmDialog },
  data() {
    return {
      searchCIE10: "",
      cie10List: [],
      loadingDiagnosticos: false,
      // Filtros para la tabla (sin incluir fechas ni estado)
      params: {
        queryPaciente: "",
        queryNumeroDocumento: "",
        queryDiagnosticos: [],
      },
      moment,
      dialogAgregar: false,
      pacienteSeleccionado: null,
      pacientes: [],
      loadingPacientes: false,
      showDeleteDialog: false,
      itemToDelete: null,
      // Campos de fecha para el diálogo de creación (no se usan como filtro)
      fechaInicio: null,
      fechaFin: null,
      sinFechaFin: false,
    }
  },
  computed: {
    ...mapState("descansosMedicos", ["total", "isLoading"]),
    ...mapGetters("descansosMedicos", ["descansosAsignados"]),
    headers() {
      return [
        { title: "Fecha Asignado", align: "center", sortable: false, key: "fecha" },
        { title: "Paciente", align: "center", sortable: false, key: "descansomedico.paciente.full_name" },
        { title: "Número de Documento", align: "center", sortable: false, key: "descansomedico.paciente.numero_documento" },
        { title: "Diagnósticos", align: "center", sortable: false, key: "diagnostico", width: "300px" },
        { title: "Estado", align: "center", sortable: false, key: "estado" },
        { title: "Seguimiento Realizado", align: "center", sortable: false, key: "realizado" },
        { title: "Terminado", align: "center", sortable: false, key: "terminado" },
        { title: "Acciones", align: "center", sortable: false, key: "ver" },
      ]
    },
  },
  methods: {
    ...mapActions("descansosMedicos", [
      "fetchdescansosasignados",
      "deleteDescanso",
      "saveDescanso",
      "exportarExcel",
      "marcarTerminado",
    ]),
    obtenerFechaFinMasReciente(item) {
      if (!item) return "Sin fecha de fin"
      let fechaFin = item.fin ? moment(item.fin) : null
      if (item.ampliacion && Array.isArray(item.ampliacion)) {
        item.ampliacion.forEach((ampliacion) => {
          if (ampliacion.fecha_fin) {
            let fechaAmpliacion = moment(ampliacion.fecha_fin)
            if (!fechaFin || fechaAmpliacion.isAfter(fechaFin)) {
              fechaFin = fechaAmpliacion
            }
          }
        })
      }
      return fechaFin ? fechaFin.format("DD/MM/YYYY") : "Sin fecha de fin"
    },
    async cargarData() {
      try {
        const userId = this.$store.state.user.id
        const params = {
          ...this.params,
          queryDiagnosticos: this.params.queryDiagnosticos.map(d => d.id),
        }
        await this.fetchdescansosasignados({ userId, params })
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message)
      }
    },
    async buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = []
        return
      }
      this.loadingDiagnosticos = true
      try {
        const data = await this.$store.dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue)
        this.cie10List = data.map(item => ({
          id: item.id,
          descripcion: `${item.codigo} - ${item.descripcion}`
        }))
      } catch (e) {
        console.error("Error al buscar diagnósticos:", e.message)
      } finally {
        this.loadingDiagnosticos = false
      }
    },
    async cargarPacientes(searchValue) {
      if (searchValue.length < 3) {
        this.pacientes = []
        return
      }
      this.loadingPacientes = true
      try {
        const data = await this.$store.dispatch("descansosMedicos/searchPacientes", { query: searchValue })
        this.pacientes = data
      } catch (e) {
        console.error("Error al buscar pacientes:", e.message)
      } finally {
        this.loadingPacientes = false
      }
    },
    verDetalle(item) {
      this.$router.push({
        name: "detalledescansomedico",
        params: { id: item.descansomedico.id, programacionId: item.id },
      })
    },
    showConfirmDialog(item) {
      this.itemToDelete = item
      this.showDeleteDialog = true
    },
    obtenerTodosLosDiagnosticos(item) {
      let diagnosticos = [...item.diagnosticos]
      if (item.ampliacion && Array.isArray(item.ampliacion)) {
        item.ampliacion.forEach(ampl => {
          if (ampl.diagnosticos && Array.isArray(ampl.diagnosticos)) {
            diagnosticos = [...diagnosticos, ...ampl.diagnosticos]
          }
        })
      }
      const diagnosticosUnicos = []
      const ids = new Set()
      diagnosticos.forEach(diag => {
        if (!ids.has(diag.id)) {
          ids.add(diag.id)
          diagnosticosUnicos.push(diag)
        }
      })
      return diagnosticosUnicos
    },
    async confirmarEliminar() {
      if (this.itemToDelete) {
        try {
          await this.deleteDescanso(this.itemToDelete.id)
          this.showDeleteDialog = false
          this.itemToDelete = null
          this.cargarData()
        } catch (e) {
          this.$store.commit("SHOW_ERROR_SNACKBAR", e.message)
        }
      }
    },
    cancelarEliminar() {
      this.showDeleteDialog = false
      this.itemToDelete = null
    },
    toggleTerminado(item) {
      const newValue = item.terminado == 1 ? 0 : 1
      this.marcarTerminado({ id: item.id, terminado: newValue })
        .then(() => {
          this.cargarData()
        })
        .catch(error => {
          console.error("Error al actualizar el estado:", error)
        })
    },
  },
  created() {
    this.cargarData()
  },
  watch: {
    "params.queryPaciente": "cargarData",
    "params.queryNumeroDocumento": "cargarData",
    "params.queryDiagnosticos": {
      handler: "cargarData",
      deep: true,
    },
    search(newVal) {
      this.cargarPacientes(newVal)
    },
  },
}
</script>
