import { createStore } from "vuex";
import axios from "axios";
import descansosMedicos from "./modules/descansosMedicos";
import formatoVisitaFamiliares from "./modules/formatoVisitaFamiliares";
import pacientes from "./modules/pacientes";
import usuarios from "./modules/usuarios";

export default createStore({
  state: {
    snackbar: {
      show: false,
      message: null,
      status: null,
    },
    isLoading: false,
    user: null,
    dialogDetalleMedicamentos: false,
    dialogCalificacionAtencionMedicamento: false,
    estaciones: [],
    id_atencion_seleccionada: null,
    estado_atencion_seleccionada: null,
    trabajador_seleccionado: null,
    seguimientos_seguimiento_seleccionado: {},
    descansos_seguimiento_seleccionado: {},
    evidencias_seguimiento_seleccionado: {},
    atencion_medicamentos: [],
    seguimiento_seleccionado: {},
    declaracion_medicamento_seleccionada: {},
    evidencia_medicamento_seleccionada: {},
    anamnesis_medicamento_seleccionada: {},
    fotos_evidencia_medicamento: [],
    dialogGuardarDescansoMedico: false,
    enfermedad_busqueda: [],
    descansos: null,
    pacientes: [],
    formato_visitantes: [],
    formato_visitante_seleccionado: {
      visitante: {
        apellidos_nombres: "",
        documento_identidad: "",
        edad: null,
        fecha_nacimiento: "",
        correo: "",
        actividad_a_realizar: "",
        contacto_emergencia: "",
        codigo_pais: "",
        telefono_contacto: "",
        empresa_otros: "",
      },
      signosvitales: {
        peso: null,
        talla: null,
        presion_arterial: null,
        frecuencia_cardiaca: null,
        frecuencia_respiratoria: null,
        saturacion_o2: null,
      },
      calificacion: {
        observaciones: null,
        aptitud_calificacion_formato_visita_id: null,
      },
      habilitacion: {
        observaciones: null,
        fv_habilitacion_id: null,
      },
      adjuntos: {},
    },
    arrayUbigeos: [],
    examenes_medicos_trabajador: [],
    estadosDeclaracionMedicamentos: [],
    aptitudesDeclaracionMedicamentos: [],
    pregunta_formato: [],
    aptitudesCalificacionFormatoVisitante: [],
    habilitacionesCalificacionFormatoVisitante: [],
    dialog_imprimir_formato_visita: false,
    postas: [],
  },
  getters: {
    menus: (state) => (state.user.rol ? state.user.rol.menu : []),
    isAuthenticated(state) {
      return !!state.user;
    },
    currentUser(state) {
      return { ...state.user };
    },
    estadosAtencionMedicamento() {
      return [
        { id: 1, color: "warning" },
        { id: 2, color: "success" },
        { id: 3, color: "info" },
        { id: 4, color: "red" },
      ];
    },
    aptitudesMedicas() {
      return [
        { id: 1, color: "success" },
        { id: 2, color: "orange" },
        { id: 3, color: "red" },
      ];
    },
    estadosSeguimiento() {
      return [
        { id: 1, text: "REVISADO", color: "success" },
        { id: 2, text: "FALTA INFORMACION", color: "info" },
        { id: 3, text: "NO CONTESTA", color: "warning" },
      ];
    },
    /*     estadoAtencionMedicamentoById() {
          return [
            { id: 0, text: 'PENDIENTE', color: 'orange' },
            { id: 1, text: 'REVISADO', color: 'success' },
          ]
        }, */
    aptitudesMediweb() {
      return [
        { id: 1, text: "APTO", color: "green" },
        { id: 2, text: "APTO CR", color: "lime-accent-3" },
        { id: 3, text: "OBSERVADO", color: "orange" },
        { id: 4, text: "NO APTO", color: "red" },
        { id: 5, text: "EVALUADO", color: "green" },
        { id: 7, text: "EXAMENES INCOMPLETOS", color: "orange" },
        { id: 11, text: "NO APTO EC", color: "red" },
      ];
    },
    estadoAtencionMedicamentoById: (state, getters) => (id) => {
      return getters.estadosAtencionMedicamento.find((es) => es.id === id);
    },
    estadoSeguimientoDescansoById: (state, getters) => (id) => {
      return getters.estadosSeguimientosDescanso.find((es) => es.id === id);
    },
    aptitudDeclaracionById: (state, getters) => (id) => {
      return getters.aptitudesMedicas.find((es) => es.id === id);
    },
    aptitudesMediwebById: (state, getters) => (id) => {
      if (id == "") return { text: "SIN CALIFICACION", color: "white" };
      return getters.aptitudesMediweb.find((es) => es.id === parseInt(id, 10));
    },
  },
  mutations: {
    SHOW_ERROR_SNACKBAR(state, message) {
      state.snackbar.show = true;
      state.snackbar.status = "error";
      state.snackbar.message = message;
    },
    SHOW_SUCCESS_SNACKBAR(state, message) {
      state.snackbar.show = true;
      state.snackbar.status = "success";
      state.snackbar.message = message;
    },
    SET_LOADING(state, show) {
      state.isLoading = show;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_ESTACIONES(state, data) {
      state.estaciones = data;
    },
    SET_DIALOG_DETALLE_MEDICAMENTOS(state, data) {
      state.dialogDetalleMedicamentos = data;
    },
    SET_DIALOG_CALIFICACION_ATENCION_MEDICAMENTO(state, data) {
      state.dialogCalificacionAtencionMedicamento = data;
    },
    SET_ID_ATENCION_SELECCIONADA(state, data) {
      state.id_atencion_seleccionada = data;
    },
    SET_ESTADO_ATENCION_SELECCIONADA(state, data) {
      state.estado_atencion_seleccionada = data;
    },
    SET_ATENCION_MEDICAMENTOS(state, data) {
      state.atencion_medicamentos = data;
    },
    SET_SEGUIMIENTO_SELECCIONADO(state, data) {
      state.seguimiento_seleccionado = data;
    },
    SET_TRABAJADOR_SELECCIONADO(state, data) {
      state.trabajador_seleccionado = data;
    },
    SET_DECLARACION_MEDICAMENTO_SELECCIONADA(state, data) {
      state.declaracion_medicamento_seleccionada = data;
    },
    SET_EVIDENCIA_MEDICAMENTO_SELECCIONADA(state, data) {
      state.evidencia_medicamento_seleccionada = data;
    },
    SET_DECLARACION_MEDICAMENTO_SELECCIONADA_OBSERVACIONES(state, data) {
      state.declaracion_medicamento_seleccionada.observaciones = data;
    },
    SET_DECLARACION_MEDICAMENTO_SELECCIONADA_APTITUD(state, data) {
      state.declaracion_medicamento_seleccionada.aptitud_declaracionmedicamento_id =
        data;
    },
    SET_DECLARACION_MEDICAMENTO_SELECCIONADA_ESTADO(state, data) {
      state.declaracion_medicamento_seleccionada.estado_declaracionmedicamento_id =
        data;
    },
    SET_DECLARACION_MEDICAMENTO_SELECCIONADA_PUNTO_ATENCION(state, data) {
      state.declaracion_medicamento_seleccionada.estacion_id = data;
    },
    SET_FOTOS_EVIDENCIA_MEDICAMENTO(state, data) {
      state.fotos_evidencia_medicamento = data;
    },
    SET_DIALOG_GUARDAR_DESCANSO_MEDICO(state, data) {
      state.dialogGuardarDescansoMedico = data;
    },
    SET_SEGUIMIENTOS_SEGUIMIENTO_SELECCIONADO(state, data) {
      state.seguimientos_seguimiento_seleccionado = data;
    },
    SET_DESCANSOS_SEGUIMIENTO_SELECCIONADO(state, data) {
      state.descansos_seguimiento_seleccionado = data;
    },
    SET_EVIDENCIAS_SEGUIMIENTO_SELECCIONADO(state, data) {
      state.evidencias_seguimiento_seleccionado = data;
    },
    SET_ANAMNESIS_SEGUIMIENTO_SELECCIONADO(state, data) {
      state.anamnesis_seguimiento_seleccionado = data;
    },
    SET_ENFERMEDAD_BUSQUEDA(state, data) {
      state.enfermedad_busqueda = data;
    },
    SET_PACIENTES(state, data) {
      state.pacientes = data;
    },
    SET_ARRAY_UBIGEOS(state, data) {
      state.arrayUbigeos = data;
    },
    SET_EXAMENES_MEDICOS_TRABAJADOR(state, data) {
      state.examenes_medicos_trabajador = data;
    },
    SET_ESTADOS_DECLARACION_MEDICAMENTOS(state, data) {
      state.estadosDeclaracionMedicamentos = data;
    },
    SET_APTITUDES_DECLARACION_MEDICAMENTOS(state, data) {
      state.aptitudesDeclaracionMedicamentos = data;
    },
    SET_FORMATO_VISITANTES(state, data) {
      state.formato_visitantes = data;
    },
    SET_FORMATO_VISITANTE_SELECCIONADO(state, data) {
      state.formato_visitante_seleccionado = data;
    },
    SET_PREGUNTA_FORMATO(state, data) {
      state.pregunta_formato = data;
    },
    SET_SIGNOS_VITALES(state, nuevosSignos) {
      state.formato_visitante_seleccionado.signosvitales = nuevosSignos;
    },
    SET_APTITUDES_CALIFICACION_FORMATO_VISITANTE(state, data) {
      state.aptitudesCalificacionFormatoVisitante = data;
    },
    SET_CALIFICACION(state, data) {
      state.formato_visitante_seleccionado.calificacion = data;
    },
    SET_DIALOG_IMPRIMIR_FORMATO_VISITA(state, data) {
      state.dialog_imprimir_formato_visita = data;
    },
    SET_POSTAS(state, data) {
      state.postas = data;
    },
    SET_HABILITACIONES_CALIFICACION_FORMATO_VISITA(state, data) {
      state.habilitacionesCalificacionFormatoVisitante = data;
    },
    SET_HABILITACION(state, data) {
      state.formato_visitante_seleccionado.habilitacion = data;
    },
  },
  actions: {
    async fetchAtenciones() {
      try {
        const { data } = await axios.get("/api/atencionDescanso");
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchSeguimientos(_, params) {
      const config = { params };
      try {
        const { data } = await axios.get("/api/seguimientos", config);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchAtencion(_, idAtencion) {
      try {
        const { data } = await axios.get(`/api/atencionDescanso/${idAtencion}`);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchAtencionMedicamento({ commit }, params) {
      const config = { params };
      try {
        const { data } = await axios.get(
          `/api/declaracionmedicamento`,
          config
        );
        commit("SET_ATENCION_MEDICAMENTOS", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchEstaciones({ commit }) {
      try {
        const { data } = await axios.get(`/api/estacion`);
        commit("SET_ESTACIONES", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async searchEnfermedad({ commit }, query) {
      const config = {
        params: {
          query,
        },
      };
      try {
        const { data } = await axios.get(`/api/enfermedades/search`, config);
        //console.log(data)
        commit("SET_ENFERMEDAD_BUSQUEDA", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async saveAtencion(_, atencion) {
      try {
        const { data } = await axios.put(
          `/api/atencionDescanso/${atencion.id}`,
          atencion
        );
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async saveAnammesis({ commit, state }, enfermedad) {
      const data = {
        enfermedad_id: enfermedad,
        atencion_id:
          state.evidencias_seguimiento_seleccionado[0].atencion_descanso_id,
      };
      try {
        const res = await axios.post(`/api/enfermedades`, data);
        commit("SET_ANAMNESIS_SEGUIMIENTO_SELECCIONADO", res.data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async saveCalificacion({ commit, dispatch }, atencion) {
      try {
        const { data } = await axios.post(`/api/saveCalificacion`, atencion);
        commit("SET_DIALOG_CALIFICACION_ATENCION_MEDICAMENTO", false);
        dispatch("fetchAtencionMedicamento");
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchSeguimiento({ commit, dispatch }, idSeguimiento) {
      try {
        const { data } = await axios.get(`/api/seguimientos/${idSeguimiento}`);
        commit("SET_SEGUIMIENTO_SELECCIONADO", data);
        commit("SET_TRABAJADOR_SELECCIONADO", data.atencion.paciente);
        commit(
          "SET_SEGUIMIENTOS_SEGUIMIENTO_SELECCIONADO",
          data.atencion.paciente.seguimiento
        );
        commit(
          "SET_DESCANSOS_SEGUIMIENTO_SELECCIONADO",
          data.atencion.descansos_medicos
        );
        commit(
          "SET_EVIDENCIAS_SEGUIMIENTO_SELECCIONADO",
          data.atencion.evidencias
        );
        commit(
          "SET_ANAMNESIS_SEGUIMIENTO_SELECCIONADO",
          data.atencion.anammesis
        );
        dispatch("fetchExamenesMedicos", data.atencion.paciente.id);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchDeclaracion({ commit }, idDeclaracion) {
      try {
        const { data } = await axios.get(
          `/api/declaracionmedicamento/${idDeclaracion}`
        );
        commit("SET_DECLARACION_MEDICAMENTO_SELECCIONADA", data);
        commit("SET_TRABAJADOR_SELECCIONADO", data.paciente);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchPaciente({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/fetchPaciente/${id}`);
        commit("SET_TRABAJADOR_SELECCIONADO", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async getUser({ commit }) {
      try {
        const { data } = await axios.get("/api/user");
        commit("SET_USER", data);
      } catch (e) {
        commit("SET_USER", null);
        throw new Error(await e.response.data.message);
      }
    },
    async login({ dispatch }, credentials) {
      try {
        await axios.post("/login", credentials);
        await dispatch("getUser");
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async register(_, credentials) {
      try {
        await axios.post("/register", credentials);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post("/logout");
        commit("SET_USER", null);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async updateSeguimiento(_, payload) {
      try {
        const { data } = await axios.put(
          `/api/seguimientos/${payload.id}`,
          payload
        );
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async changeLocale(_, code) {
      try {
        axios.defaults.headers.common["Content-Language"] = code;
        const { data } = await axios.get(`/api/greeting/${code}`);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async saveDeclaracionMedicamento({ state }) {
      const data = {
        declaracion_medicamento: state.declaracion_medicamento_seleccionada,
        id_atencion: state.declaracion_medicamento_seleccionada.id,
      };
      try {
        await axios.post("/api/saveCalificacion", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchPacientes({ commit }, search) {
      try {
        const { data } = await axios.get(`/api/listarPaciente/` + search);
        commit("SET_PACIENTES", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async guardarDatosLogin(_, data) {
      //console.log(data);
      try {
        await axios.post(`/api/guardarDatosLogin`, data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async searchUbigeos({ commit }, params) {
      try {
        const res = await axios.post(`/api/searchUbigeo`, params);
        commit("SET_ARRAY_UBIGEOS", await res.data.items);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async searchPacienteByDni({ commit }, dni) {
      try {
        const { data } = await axios.get(`/api/searchPacienteByDni/${dni}`);
        data.validacion_reniec = {};
        data.idempresa = 7;
        commit("SET_TRABAJADOR_SELECCIONADO", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async savePaciente(_, data) {
      try {
        await axios.post("/api/savePaciente", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchExamenesMedicos({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/fetchExamenesMedicos/${id}`);
        commit("SET_EXAMENES_MEDICOS_TRABAJADOR", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchEstadosDeclaracionMedicamentos({ commit }) {
      try {
        const { data } = await axios.get("/api/estadoDeclaracionmedicamento");
        commit("SET_ESTADOS_DECLARACION_MEDICAMENTOS", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchAptitudesDeclaracionMedicamentos({ commit }) {
      try {
        const { data } = await axios.get("/api/aptitudDeclaracionmedicamento");
        commit("SET_APTITUDES_DECLARACION_MEDICAMENTOS", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async reporteExcel(_, params) {
      const config = {
        data: params,
      };
      try {
        await axios
          .post("/api/excelAtencionMedicamentos", config, {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "atencion_medicamentos.xlsx");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) {
        console.log(error);
        throw new Error(await error.response.data.message);
      }
    },
    async fetchFormatoVisita({ commit }, params) {
      const config = { params };
      try {
        const { data } = await axios.get(`/api/formatovisitas`, config);
        commit("SET_FORMATO_VISITANTES", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchFormatoVisitaID({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/formatovisitas/${id}`);
        commit("SET_FORMATO_VISITANTE_SELECCIONADO", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async FetchCuestionario({ commit }) {
      try {
        const { data } = await axios.get(`/api/preguntaformato`);
        commit("SET_PREGUNTA_FORMATO", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchAptitudCalificacionFormatoVisitante({ commit }) {
      try {
        const { data } = await axios.get(`/api/aptitudcalificacionfv`);
        commit("SET_APTITUDES_CALIFICACION_FORMATO_VISITANTE", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchFvHabilitacionFormatoVisitante({ commit }) {
      try {
        const { data } = await axios.get(`/api/Fvhabilitacionfamiliar`);
        commit("SET_HABILITACIONES_CALIFICACION_FORMATO_VISITA", data);
        return data;
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async guardarFormatoVisita({ state,commit }) {
      try {
        /* const { data } =  */ await axios.post(
          `/api/formatovisitas`,
          state.formato_visitante_seleccionado
        );
        /*  commit("SET_APTITUDES_CALIFICACION_FORMATO_VISITANTE", data); */
        /* return data; */
        commit("SHOW_SUCCESS_SNACKBAR","Formato Actualizado Correctamente");
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async showPdfFormatoVisita(_, id) {
      const config = {
        responseType: "blob",
      };
      try {
        const response = await axios.get(
          `api/showFormatoVisitaPdf/${id}`,
          config
        );
        return window.URL.createObjectURL(new Blob([response.data]));
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchPostas({ commit }) {
      try {
        const { data } = await axios.get(`/api/posta`);
        commit("SET_POSTAS", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async programarVisita({ dispatch }, programacion) {
      try {
        await axios.post(`/api/programacion`, programacion);
        dispatch("fetchFormatoVisita");
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async deleteFormato({ dispatch }, id) {
      try {
        await axios.delete(`/api/formatovisitas/${id}`);
        dispatch("fetchFormatoVisita");
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    }    
  },
  modules: {
    descansosMedicos,
    formatoVisitaFamiliares,
    pacientes,
    usuarios
  },
});
