import axios from "axios";

const usuarios = {
  namespaced: true,
  state: {
    data: [],
    roles: [],
    total: 0,
    especialidades: [],
  },
  getters: {
    usuarios(state) {
      return state.data;
    },
    roles(state) {
      return state.roles;
    },
    especialidades(state) {
      return state.especialidades;
    },
  },
  mutations: {
    SET_USUARIOS(state, usuarios) {
      state.data = usuarios;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_ESPECIALIDADES(state, especialidades) {
      state.especialidades = especialidades;
    },
  },
  actions: {
    async fetchUsuarios({ commit }, { page = 1, per_page = 10, search = "" }) {
      try {
        const response = await axios.get("/api/usuario", {
          params: { page, per_page, search },
        });
        commit("SET_USUARIOS", response.data.data);
        commit("SET_TOTAL", response.data.total);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },
    async fetchUsuariosDistribucion({ commit }) {
      try {
        const { data } = await axios.get("/api/usuariodistribucion");
        commit("SET_USUARIOS", data);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },
    async fetchRoles({ commit }) {
      try {
        const { data } = await axios.get("/api/rol");
        const rolesActivos = data.filter((r) => r.estado === 1);
        const formateados = rolesActivos.map((r) => ({
          text: r.descripcion,
          value: r.id,
        }));
        commit("SET_ROLES", formateados);
      } catch (error) {
        console.error("Error al obtener roles:", error);
      }
    },

    async fetchEspecialidades({ commit }) {
      try {
        const { data } = await axios.get("/api/especialidadusuario");
        const formateadas = data.map((e) => ({
          text: e.descripcion,
          value: e.id,
        }));
        commit("SET_ESPECIALIDADES", formateadas);
      } catch (error) {
        console.error("Error al obtener especialidades:", error);
      }
    },

    async saveUsuario(_, usuario) {
      try {
        await axios.post("/api/usuario", usuario);
      } catch (error) {
        console.error("Error al guardar usuario:", error);
        throw error;
      }
    },

    async updateUsuario(_, usuario) {
      try {
        await axios.put(`/api/usuario/${usuario.id}`, usuario);
      } catch (error) {
        console.error("Error al actualizar usuario:", error);
        throw error;
      }
    },

    async deleteUsuario(_, id) {
      try {
        await axios.delete(`/api/usuario/${id}`);
      } catch (error) {
        console.error("Error al eliminar usuario:", error);
        throw error;
      }
    },
  },
};

export default usuarios;
