<template>
  <v-card>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-spacer></v-spacer>
      <h5 class="white--text">SALUD OCUPACIONAL</h5>
      <v-spacer></v-spacer>
      <v-btn icon @click="guardarCambios">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text v-if="paciente">
      <!-- Enfermedades Cronicas -->
      <v-autocomplete v-model="enfermedades_cronicas" :items="cie10List" item-title="descripcion" item-value="id"
        label="Buscar Enfermedades Crónicas" multiple chips density="compact" hide-details clearable closable-chips
        v-model:search="searchCIE10" :loading="loadingDiagnosticos" @update:search="buscarDiagnosticos" return-object>
        <template v-slot:selection="{ item, index }">
          <v-chip close @click:close="eliminarEnfermedad(index)">
            {{ item.descripcion }}
          </v-chip>
        </template>
      </v-autocomplete>



      <!-- Restricciones Medicas -->
      <v-container class="pa-0">
        <v-btn color="orange-darken-1" class="mt-2" variant="tonal" @click="agregarRestriccion">
          <v-icon left>mdi-plus</v-icon> Agregar Restriccion
        </v-btn>

        <v-row v-for="(restriccion, index) in restricciones" :key="index" align="center">
          <v-col>
            <v-text-field v-model="restricciones[index]" label="Restriccion SO" density="compact"
              @click:append="eliminarRestriccion(index)" hide-details class="mb-2" append-icon="mdi-delete">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Uso de Bloqueadores / EPP -->
      <v-container class="pa-0">
        <v-btn color="orange-darken-1" class="mt-2" @click="agregarBloqueador" variant="tonal">
          <v-icon left>mdi-plus</v-icon> Agregar Bloqueador / EPP
        </v-btn>

        <v-row v-for="(bloqueador, index) in epp_bloqueadores" :key="index" align="center">
          <v-col>
            <v-text-field v-model="epp_bloqueadores[index]" label="Especifique Bloqueador / EPP" density="compact"
              item-title="descripcion" @click:append="eliminarBloqueador(index)" hide-details class="mb-2"
              append-icon="mdi-delete">
            </v-text-field>
          </v-col>
        </v-row>

      </v-container>

      <!-- Aptitud Medica -->
      <v-container class="pa-0">
        <v-row align="center" dense>
          <v-col cols="6">
            <span class="font-weight-bold">No Apto SO</span>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="aptitud" false-value="No" true-value="Si" color="orange" hide-details density="compact">
            </v-switch>
          </v-col>
        </v-row>

        <v-text-field v-if="aptitud === 'Si'" v-model="aptitud_detalle" label="Motivo de No Apto" density="compact"
          hide-details class="mb-2"></v-text-field>
      </v-container>

      <!-- Condicion Especial -->
      <v-container class="pa-0">
        <v-row align="center" dense>
          <v-col cols="6">
            <span class="font-weight-bold">Especial SO</span>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="especial" false-value="No" true-value="Si" color="orange" hide-details density="compact">
            </v-switch>
          </v-col>
        </v-row>

        <v-text-field v-if="especial === 'Si'" v-model="especial_detalle" label="Detalle de Condicion Especial"
          density="compact" hide-details class="mb-2"></v-text-field>
      </v-container>
    </v-card-text>

    <!-- Snackbar para mensajes de exito o error -->
    <v-snackbar v-model="snackbar" :timeout="3000" color="green">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: "SaludOcupacional",
  computed: {
    paciente() {
      return this.$store.state.descansosMedicos.descansoSeleccionado?.paciente || {};
    },
  },
  data() {
    return {
      enfermedades_cronicas: [],
      restricciones: [],
      epp_bloqueadores: [],
      aptitud: "No",
      aptitud_detalle: "",
      especial: "No",
      especial_detalle: "",
      cie10List: [],
      searchCIE10: "",
      loadingDiagnosticos: false,
      snackbar: false,
      snackbarMessage: "",
    };
  },
  watch: {
    paciente: {
      immediate: true,
      handler(nuevoPaciente) {
        if (nuevoPaciente) {

          this.enfermedades_cronicas = nuevoPaciente.enfermedades_cronicas?.map(e => ({
            id: e.id,
            descripcion: `${e.codigo} - ${e.descripcion}` // ✅ Muestra código + descripción
          })) || [];

          this.restricciones = nuevoPaciente.restricciones?.map(r => r.descripcion) || [];
          this.epp_bloqueadores = nuevoPaciente.bloqueadoresepp?.map(b => b.descripcion) || [];

          this.aptitud = nuevoPaciente.noapto ? "Si" : "No";
          this.aptitud_detalle = nuevoPaciente.noapto?.descripcion || "";

          this.especial = nuevoPaciente.especial ? "Si" : "No";
          this.especial_detalle = nuevoPaciente.especial?.descripcion || "";
        }
      },
    },
    // Si el switch cambia, actualizamos el store
    aptitud(newVal) {
      // Si "Si" se selecciona, se envía un objeto (por ejemplo, con la descripción que se encuentra en aptitud_detalle)
      // y si es "No", se envía null.
      const payload = newVal === "Si" ? { descripcion: this.aptitud_detalle || null } : null;
      this.$store.dispatch("descansosMedicos/actualizarNoApto", payload);
    },
    // Si la descripción cambia y el switch está en "Si", actualizamos también
    aptitud_detalle(newVal) {
      if (this.aptitud === "Si") {
        this.$store.dispatch("descansosMedicos/actualizarNoApto", { descripcion: newVal });
      }
    },
  },
  methods: {
    eliminarEnfermedad(index) {
      this.enfermedades_cronicas.splice(index, 1); // Elimina por índice sin afectar el resto
    },

    async buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      try {
        const data = await this.$store.dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue);
        this.cie10List = data.map((item) => ({
          id: item.id,
          descripcion: `${item.codigo} - ${item.descripcion}`,
        }));
      } catch (e) {
        console.error("Error al buscar enfermedades CIE-10:", e.message);
      } finally {
        this.loadingDiagnosticos = false;
      }
    },

    agregarRestriccion() {
      this.restricciones.push("");
    },

    eliminarRestriccion(index) {
      this.restricciones.splice(index, 1);
    },

    agregarBloqueador() {
      this.epp_bloqueadores.push("");
    },

    eliminarBloqueador(index) {
      this.epp_bloqueadores.splice(index, 1);
    },
    async guardarCambios() {
      try {
        if (!this.paciente.id) {
          this.snackbarMessage = "Error: Paciente no encontrado.";
          this.snackbar = true;
          return;
        }

        const datosPaciente = {
          // 🔹 Convertimos `enfermedades_cronicas` en un array de IDs para enviarlo correctamente
          enfermedades_cronicas: this.enfermedades_cronicas.map(e => e.id),

          restricciones: this.restricciones
            .filter(r => r.trim() !== "")
            .map(r => ({ descripcion: r })),

          bloqueadoresepp: this.epp_bloqueadores
            .filter(e => e.trim() !== "")
            .map(e => ({ descripcion: e })),

          // 🔹 Si el switch está en "No", enviamos null para eliminarlo en el backend
          noapto: this.aptitud === "Si" ? { descripcion: this.aptitud_detalle || null } : null,
          especial: this.especial === "Si" ? { descripcion: this.especial_detalle || null } : null,
        };

        await this.$store.dispatch("descansosMedicos/guardarSaludOcupacional", {
          id: this.paciente.id,
          datosPaciente
        });

        this.snackbarMessage = "Cambios guardados correctamente";
        this.snackbar = true;
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        this.snackbarMessage = "Error al guardar los cambios";
        this.snackbar = true;
      }
    }



  },
};
</script>
