<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-spacer></v-spacer>
      <h5 class="white--text">DATOS DEL DESCANSO MÉDICO</h5>
      <v-spacer></v-spacer>
      <!-- Botón siempre habilitado -->
      <v-btn icon @click="guardarDescanso" class="white--text">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <!-- El formulario se valida al enviar -->
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="d-flex align-center">
          <!-- Selección de Rango de Fechas -->
          <v-text-field v-model="fechaInicioFormateada" label="Fecha de Inicio" readonly density="compact" hide-details
            prepend-icon="mdi-calendar-start" class="mr-4" />
          <v-text-field v-model="fechaFinFormateada" label="Fecha de Fin" readonly density="compact" hide-details
            prepend-icon="mdi-calendar-end" />
          <v-chip v-if="dias !== null" class="ml-4" color="primary" outlined>
            {{ dias }} días
          </v-chip>
        </div>

        <!-- Autocomplete Diagnósticos CIE10 -->
        <v-autocomplete v-model="selectedDiagnosticos" :items="cie10List" label="Seleccione Diagnósticos" multiple chips
          density="compact" closable-chips item-title="descripcion" item-value="id" :return-object="true" required
          :rules="[value => (value && value.length > 0) || 'Debe seleccionar al menos un diagnóstico']"
          prepend-icon="mdi-hospital-box" v-model:search="searchCIE10" :loading="loadingDiagnosticos" hide-no-data
          clearable @update:search="buscarDiagnosticos"></v-autocomplete>

        <!-- Detalles por Diagnóstico -->
        <div v-if="selectedDiagnosticos.length">
          <h6 class="mt-3">Detalles por Diagnóstico</h6>
          <div v-for="(diagnostico, index) in selectedDiagnosticos" :key="diagnostico.id">
            <v-textarea v-model="selectedDiagnosticos[index].detalle_cie10"
              :label="`Detalle del diagnóstico: ${diagnostico.descripcion}`" prepend-icon="mdi-note-text" rows="2"
              auto-grow density="compact" hide-details />
          </div>
        </div>

        <!-- Select de Estado -->
        <v-select v-model="selectedEstado" :items="estadosDisponibles" item-value="value" item-title="text"
          label="Estado" dense required hide-details density="compact" prepend-icon="mdi-checkbox-marked-circle"
          :rules="[value => !!value || 'El estado es requerido']"></v-select>

        <!-- Select de Reincorporación (si el estado es FINALIZADO) -->
        <v-select v-if="selectedEstado === 2" v-model="selectedReincorporacion" :items="reincorporaciones"
          item-value="id" item-title="descripcion" label="Reincorporación" dense hide-details density="compact"
          prepend-icon="mdi-home" :rules="[value => !!value || 'La reincorporación es requerida']"></v-select>

        <!-- Select de Origen -->
        <v-select v-model="selectedOrigen" :items="origenes" item-value="id" item-title="descripcion" label="Origen"
          dense required density="compact" prepend-icon="mdi-map-marker"
          :rules="[value => !!value || 'El origen es requerido']"></v-select>

        <!-- Select de Motivo del Descanso -->
        <v-select v-model="motivoDescanso" :items="motivos" item-value="id" item-title="descripcion" density="compact"
          label="Motivo del Descanso" required prepend-icon="mdi-alert-circle"
          :rules="[value => !!value || 'El motivo es requerido']"></v-select>

        <!-- Comentario SMCV -->
        <v-textarea v-model="comentario_smcv" label="Comentario SMCV" prepend-icon="mdi-comment-text-outline" rows="2"
          auto-grow density="compact" hint="Este campo aparecerá en el reporte Excel para SMCV." />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "CIE10EstadoControl",
  data() {
    return {
      fechaInicioFormateada: "",
      fechaFinFormateada: "",
      dias: null,
      searchCIE10: "",
      cie10List: [],
      loadingDiagnosticos: false,
      valid: false,
      selectedDiagnosticos: [],
      selectedEstado: null,
      fechaRango: [],
      selectedOrigen: null,
      selectedReincorporacion: null,
      motivoDescanso: null,
      comentario_smcv: "",
      estados: [
        { value: 1, text: "SEGUIMIENTO" },
        { value: 2, text: "FINALIZADO" }
      ]
    };
  },
  computed: {
    ...mapState("descansosMedicos", [
      "descansoSeleccionado",
      "subcategoriasCIE10",
      "motivos",
      "origenes",
      "reincorporaciones"
    ]),
    // Se mantienen todas las opciones, sin filtrar FINALIZADO
    estadosDisponibles() {
      return this.estados;
    }
  },
  watch: {
    descansoSeleccionado: {
      immediate: true,
      handler(nuevoValor) {
        if (!nuevoValor) return;

        // Fecha de inicio
        const inicio = moment(nuevoValor.inicio);
        this.fechaInicioFormateada = inicio.format("DD-MM-YYYY");

        // Buscar última fecha de ampliación o usar la del descanso
        const ampliaciones = nuevoValor?.ampliacion || [];
        let ultimaFechaFin = moment(nuevoValor.fin);

        if (ampliaciones.length) {
          const fechasFin = ampliaciones.map(a => moment(a.fecha_fin));
          ultimaFechaFin = moment.max(fechasFin);
        }

        this.fechaFinFormateada = ultimaFechaFin.format("DD-MM-YYYY");

        // Calcular días
        this.dias = ultimaFechaFin.diff(inicio, "days") + 1;

        // Resto del formulario
        this.selectedEstado = nuevoValor.estado?.id || null;
        this.selectedOrigen = nuevoValor.origen?.[0]?.id || null;
        this.selectedReincorporacion = nuevoValor.reincorporacion?.[0]?.id || null;
        this.motivoDescanso = nuevoValor.motivo?.[0]?.id || null;
        this.selectedDiagnosticos = nuevoValor.diagnosticos?.map(d => ({
          id: d.id,
          codigo: d.codigo,
          descripcion: `${d.codigo} ${d.descripcion}`,
          detalle_cie10: d.pivot?.detalle_cie10 ?? ""
        })) || [];
        this.comentario_smcv = nuevoValor.comentario_smcv || "";
      }
    },
    fechaRango(newVal) {
      if (!newVal || newVal.length === 0) return;
      this.calculateDias();
    }
  },
  methods: {
    ...mapActions("descansosMedicos", ["fetchSubcategoriasCIE10"]),
    buscarDiagnosticos(searchValue) {
      if (!searchValue || searchValue.length < 3) {
        this.cie10List = [];
        return;
      }
      this.loadingDiagnosticos = true;
      this.$store
        .dispatch("descansosMedicos/fetchSubcategoriasCIE10", searchValue)
        .then(data => {
          this.cie10List = data.map(item => ({
            id: item.id,
            codigo: item.codigo,
            descripcion: `${item.codigo} - ${item.descripcion}`
          }));
        })
        .catch(e => {
          console.error("Error al buscar diagnósticos CIE10:", e.message);
        })
        .finally(() => {
          this.loadingDiagnosticos = false;
        });
    },
    calculateDias() {
      if (this.fechaRango.length >= 2) {
        const inicio = moment(this.fechaRango[0]);
        const fin = moment(this.fechaRango[this.fechaRango.length - 1]);
        this.dias = fin.diff(inicio, "days") + 1;
      } else if (this.fechaRango.length === 1) {
        this.dias = 1;
      } else {
        this.dias = null;
      }
    },
    actualizarFechas() {
      if (this.fechaRango && this.fechaRango.length) {
        this.fechaRango = this.fechaRango.map(fecha =>
          fecha instanceof Date ? fecha : new Date(fecha)
        );
      }
      this.calculateDias();
    },
    async guardarDescanso() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) {
        this.$store.commit(
          "SHOW_ERROR_SNACKBAR",
          "Por favor, complete todos los campos obligatorios."
        );
        return;
      }
      // Si se intenta guardar como FINALIZADO y el paciente tiene "noapto", se muestra el error.
      if (this.selectedEstado === 2 && this.descansoSeleccionado?.paciente?.noapto) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", "No puede dar como finalizado ya que está como no apto en Salud Ocupacional");
        return;
      }
      const fechaInicio =
        this.fechaRango.length > 0
          ? moment(this.fechaRango[0]).format("YYYY-MM-DD")
          : null;
      const fechaFin =
        this.fechaRango.length > 1
          ? moment(this.fechaRango[this.fechaRango.length - 1]).format("YYYY-MM-DD")
          : fechaInicio;
      const descansoData = {
        id: this.descansoSeleccionado?.id,
        motivo: this.motivoDescanso,
        diagnosticos: this.selectedDiagnosticos.map(d => ({
          id: d.id,
          detalle_cie10: d.detalle_cie10
        })),
        estado: this.selectedEstado,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        origen: this.selectedOrigen,
        reincorporacion: this.selectedReincorporacion,
        comentario_smcv: this.comentario_smcv
      };
      console.log("Datos enviados para guardar:", descansoData);
      try {
        await this.$store.dispatch("descansosMedicos/saveDescanso", descansoData);
        this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Descanso médico guardado correctamente.");
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    }
  },
  async mounted() {
    this.fetchSubcategoriasCIE10();
    this.$store.dispatch("descansosMedicos/fetchOrigenes");
    this.$store.dispatch("descansosMedicos/fetchMotivos");
    this.$store.dispatch("descansosMedicos/fetchReincorporaciones");
  }
};
</script>
