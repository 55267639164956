<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4" density="compact">
      <v-spacer></v-spacer>
      <h5>Fecha de último EMA/EMPO: <strong>{{ ultimaFecha }}</strong></h5>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = true">
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Diálogo con el historial en Timeline -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="indigo-darken-4" density="compact">
          <v-spacer></v-spacer>
          <h5 class="white--text">Historial de EMAs / EMPOs</h5>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <div class="timeline-container">
            <v-timeline side="end" align="start">
              <v-timeline-item
                v-for="(examen, i) in examenes_medicos"
                :dot-color="$store.getters.aptitudesMediwebById(examen.aptitud).color"
                :key="i"
                size="small"
              >
                <div class="d-flex">
                  <strong class="me-4">
                    {{ moment(examen.fecha).format('DD-MMM-YYYY') }}
                  </strong>
                  <div>
                    <strong>{{ examen.descripcion }}</strong>
                    <div class="text-caption">
                      <span>Aptitud: {{ $store.getters.aptitudesMediwebById(examen.aptitud).text }}</span>
                    </div>
                  </div>
                </div>

                <!-- Mostrar Observaciones si existen -->
                <v-expansion-panels v-if="examen.observaciones.length" class="mt-3">
                  <v-expansion-panel
                    v-for="(obs, j) in examen.observaciones"
                    :key="j"
                  >
                    <v-expansion-panel-title>
                      <strong>{{ obs.especialidad }}</strong> - {{ moment(obs.fecha).format('DD-MMM-YYYY') }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      {{ obs.observaciones }}
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "ExamenesOcupacionalesTimeline",
  data() {
    return {
      dialog: false,
      moment,
    };
  },
  computed: {
    examenes_medicos() {
      return this.$store.state.examenes_medicos_trabajador;
    },
    ultimaFecha() {
      if (!this.examenes_medicos.length) return "No disponible";
      return moment(this.examenes_medicos[0].fecha).format('DD-MMM-YYYY');
    }
  },
  async created() {
    if (
      this.$store.state.descansosMedicos.descansoSeleccionado &&
      this.$store.state.descansosMedicos.descansoSeleccionado.paciente
    ) {
      await this.$store.dispatch(
        "fetchExamenesMedicos",
        this.$store.state.descansosMedicos.descansoSeleccionado.paciente.id
      );
    }
  }
};
</script>

<style scoped>
/* Contenedor con altura fija y scroll cuando el contenido supera el límite */
.timeline-container {
  max-height: 500px; /* Altura máxima antes de que aparezca el scroll */
  overflow-y: auto; /* Habilita el scroll vertical cuando el contenido excede la altura */
  padding-right: 10px; /* Espaciado para evitar que el scroll tape el contenido */
}
</style>
